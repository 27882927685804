
import { APPLICATION_URL } from "../config/apiConfig";
import { Application } from "../Models/ApplicationModel";
import { apiService } from "./apiService";

const applicationService = {
    getApplications: async (): Promise<Application[]> => {
        return await apiService(APPLICATION_URL, {
            method: 'GET',
            excludeAppId: true
        });
    },

    addApplication: async (applicationReq: Application): Promise<void> => {
        return await apiService(APPLICATION_URL, {
            method: 'POST',
            body: applicationReq,
            excludeAppId: true
        });
    },

    updateApplication: async (applicationReq: Application): Promise<void> => {
        const url = `${APPLICATION_URL}/${applicationReq.id}`;
        return await apiService(url, {
            method: 'PUT',
            body: applicationReq,
            excludeAppId: true
        });
    },

    deleteApplication: async (id: number): Promise<void> => {
        const url = `${APPLICATION_URL}/${id}`;
        return await apiService(url, {
            method: 'DELETE',
            excludeAppId: true
        });
    },
}

export default applicationService;
