import React, {
  createContext,
  useContext,
} from "react";
import { useNotification } from "./NotificationContext";
import { NotificationTypes } from "../Enums/NotificationTypes";
import { Category } from "../Models/Category";
import categoryService from "../services/categoryService";
import { useLoader } from "./LoaderContext";
import { useUserDataContext } from "./UserDataContext";

interface CategoriesContextType {
  categoriesAll: Category[];
  addCategory: (category: Category) => void;
  updateCategory: (category: Category) => void;
  deleteCategory: (categoryId: number) => void;
}

const CategoriesContext = createContext<CategoriesContextType | null>(null);

export const CategoriesProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { showNotification } = useNotification();
  const { setLoading } = useLoader();
  const { categoriesAll, setCategoriesAll } = useUserDataContext();

  const addCategory = async (category: Category) => {
    try {
      setLoading(true);
      const categoryAdd = await categoryService.addCategory(category);
      setCategoriesAll((prev) => [categoryAdd, ...prev]);
      showNotification("Se ha agregado la categoría correctamente.",  NotificationTypes.SUCCESS );
    } catch (error) {
      console.error("Error agregando categoría:", error);
      showNotification("Error al agregar la categoría.", NotificationTypes.ERROR);
    } finally {
      setLoading(false);
    }
  };

  const updateCategory = async (updatedCategory: Category) => {
    try {
      setLoading(true);
      const categoryUpdate = await categoryService.updateCategory(updatedCategory);
      setCategoriesAll((prev) => prev.map((p) => (p.id === categoryUpdate.id ? categoryUpdate : p))); 
      showNotification("Se ha actualizado la categoría correctamente.", NotificationTypes.SUCCESS );
    } catch (error) {
      console.error("Error actualizando categoría:", error);
      showNotification( "Error al actualizar la categoría.", NotificationTypes.ERROR );
    } finally {
      setLoading(false);
    }
  };

  const deleteCategory = async (categoryId: number) => {
    try {
      setLoading(true);
      await categoryService.deleteCategory(categoryId);
      setCategoriesAll((prev) => prev.filter((c) => c.id !== categoryId)); 
      showNotification("Se ha eliminado la categoría correctamente.", NotificationTypes.SUCCESS );
    } catch (error) {
      console.error("Error eliminando categoría:", error);
      showNotification( "Error al eliminar la categoría.", NotificationTypes.ERROR );
    } finally {
      setLoading(false);
    }
  };

  return (
    <CategoriesContext.Provider
      value={{ categoriesAll, addCategory, updateCategory, deleteCategory }} >
      {children}
    </CategoriesContext.Provider>
  );
};

export const useCategoriesContext = () => {
  const context = useContext(CategoriesContext);
  if (!context) {
    throw new Error(
      "useCategoriesContext must be used within a CategoriesProvider"
    );
  }
  return context;
};
