import React from 'react';
import IconButton from '@mui/material/IconButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Badge from '@mui/material/Badge';
import { useTheme } from '@mui/material';
import { useCart } from '../../context/CartContext';

interface ShoppingCartButtonProps {
  onClick: () => void;
}

const ShoppingCartButton: React.FC<ShoppingCartButtonProps> = ({ onClick }) => {
  const theme = useTheme();
  const { cart } = useCart();

  return (
    <IconButton color="inherit" onClick={onClick}>
      <Badge badgeContent={cart.length} color="error">
        <ShoppingCartIcon sx={{ color: theme.palette.text.primary }} />
      </Badge>
    </IconButton>
  );
};

export default ShoppingCartButton;
