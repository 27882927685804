import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useNotification } from "./NotificationContext";
import { NotificationTypes } from "../Enums/NotificationTypes";
import { TypeUser } from "../Models/TypeUser";
import userService from "../services/userService";
import { useLoader } from "./LoaderContext";

interface TypeUsersContextType {
  typeUsersAll: TypeUser[];
 // addTypeUser: (typeUser: TypeUser) => void;
 // updateTypeUser: (typeUser: TypeUser) => void;
 // deleteTypeUser: (typeUserId: number) => void;
}

const TypeUsersContext = createContext<TypeUsersContextType | null>(null);

export const TypeUsersProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { showNotification } = useNotification();
  const { setLoading } = useLoader();

  const [typeUsersAll, setTypeUsersAll] = useState<TypeUser[]>(
    localStorage.getItem("typeUsersAll")
      ? JSON.parse(localStorage.getItem("typeUsersAll")!)
      : []
  );
/*
  const addTypeUser = async (typeUser: TypeUser) => {
    try {
      await userService.add();
      fetchTypeUsers();
      showNotification(
        "Se ha agregado el tipo de usuario correctamente.",
        NotificationTypes.SUCCESS
      );
    } catch (error) {
      console.error("Error agregando tipo de usuario:", error);
      showNotification(
        "Error al agregar el tipo de usuario.",
        NotificationTypes.ERROR
      );
    }
  };

  const updateTypeUser = async (updatedTypeUser: TypeUser) => {
    try {
      await typeUserService.updateTypeUser(updatedTypeUser);
      fetchTypeUsers();
      showNotification(
        "Se ha actualizado el tipo de usuario correctamente.",
        NotificationTypes.SUCCESS
      );
    } catch (error) {
      console.error("Error actualizando tipo de usuario:", error);
      showNotification(
        "Error al actualizar el tipo de usuario.",
        NotificationTypes.ERROR
      );
    }
  };

  const deleteTypeUser = async (typeUserId: number) => {
    try {
      await typeUserService.deleteTypeUser(typeUserId);
      fetchTypeUsers();
      showNotification(
        "Se ha eliminado el tipo de usuario correctamente.",
        NotificationTypes.SUCCESS
      );
    } catch (error) {
      console.error("Error eliminando tipo de usuario:", error);
      showNotification(
        "Error al eliminar el tipo de usuario.",
        NotificationTypes.ERROR
      );
    }
  };
  */

  const fetchTypeUsers = useCallback(async () => {
    try {
      setLoading(true);
      const fetchedTypeUsers = await userService.getUserTypes();
      setTypeUsersAll(fetchedTypeUsers);
      localStorage.setItem("typeUsersAll", JSON.stringify(fetchedTypeUsers));
    } catch (error) {
      console.error("Error fetching type users:", error);
      showNotification(
        "Error al cargar los tipos de usuario.",
        NotificationTypes.ERROR
      );
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const loadTypeUsersIfEmpty = async () => {
      if (typeUsersAll.length === 0) {
        console.log("TypeUsersAll está vacío, recuperando datos...");
        await fetchTypeUsers();
      }
    };
    loadTypeUsersIfEmpty();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TypeUsersContext.Provider
      value={{
        typeUsersAll
      }}
    >
      {children}
    </TypeUsersContext.Provider>
  );
};

export const useTypeUsersContext = () => {
  const context = useContext(TypeUsersContext);
  if (!context) {
    throw new Error(
      "useTypeUsersContext must be used within a TypeUsersProvider"
    );
  }
  return context;
};
