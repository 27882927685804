import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";
import { Product } from "../Models/Product";
import { ShoppingCartItem } from "../Models/ShoppingCartItem";

interface CartContextType {
  cart: ShoppingCartItem[];
  addToCart: (product: Product, quantity: number) => void;
  removeFromCart: (productId: number) => void;
  clearCart: () => void;
  updateQuantity: (productId: number, quantity: number, stockMax: number) => void;
  updatePrice: (productId: number, price: number) => void;
  getTotalPrice: () => number;
  openSnackbar: boolean; // Estado del snackbar
  snackbarMessage: string; // Mensaje del snackbar
  handleCloseSnackbar: () => void; // Función para cerrar el snackbar
}

const CartContext = createContext<CartContextType | undefined>(undefined);

interface CartContextProps {
  children: ReactNode;
}

export const CartProvider: React.FC<CartContextProps> = ({ children }) => {
  const MAX_CART_ITEMS = 100; 
  const [cart, setCart] = useState<ShoppingCartItem[]>(() => {
    const storedCart = localStorage.getItem("cartData");
    return storedCart ? JSON.parse(storedCart) : [];
  });

  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    localStorage.setItem("cartData", JSON.stringify(cart));
  }, [cart]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const addToCart = (product: Product, quantity: number) => {
    setCart((prevCart) => {
      if (prevCart.length >= MAX_CART_ITEMS) {
        setSnackbarMessage("No puedes agregar más productos al carrito.");
        setOpenSnackbar(true);
        return prevCart;
      }

      const cartCopy = [...prevCart];
      const productIndex = cartCopy.findIndex((item) => item.product.id === product.id);

      if (quantity < 1) {
        return prevCart;
      }

      // Validación de cantidad
      if (quantity < 1) {
        return prevCart; // No agregamos si la cantidad es menor que 1
      }

      // Validación de stock
      if (product.stock < quantity) {
        // Ajustamos la cantidad a la disponible
        quantity = product.stock;
        setSnackbarMessage(
          "Se ha ajustado la cantidad al máximo disponible en stock."
        );
        setOpenSnackbar(true);
      }

      // Si el producto ya está en el carrito, actualizamos la cantidad
      if (productIndex !== -1) {
        const updatedItem = {
          ...cartCopy[productIndex],
          quantity: cartCopy[productIndex].quantity + quantity,
        };
        cartCopy[productIndex] = updatedItem;
      } else {
        // Si no está en el carrito, lo añadimos
        cartCopy.push({ product, quantity });
      }

      return cartCopy;
    });
  };

  const removeFromCart = (productId: number) => {
    setCart((prevCart) => prevCart.filter((item) => item.product.id !== productId));
  };

  const clearCart = () => {
    setCart([]);
  };

  const updateQuantity = (productId: number, quantity: number, productStock: number) => {
    setCart((prevCart) => {
      if (quantity > productStock) {
        quantity = productStock;
        setSnackbarMessage(
          "Se ha ajustado la cantidad al máximo disponible en stock."
        );
        setOpenSnackbar(true);
      }
  
      if (quantity === 0) {
        setSnackbarMessage("Producto eliminado del carrito.");
        setOpenSnackbar(true);
        return prevCart.filter((item) => item.product.id !== productId);
      }
  
      return prevCart.map((item) =>
        item.product.id === productId ? { ...item, quantity } : item
      );
    });
  };
  

  const updatePrice = (productId: number, price: number) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.product.id === productId ? { ...item, product: { ...item.product, unitSalePrice: price } } : item
      )
    );
  };

  const getTotalPrice = () => {
    return cart.reduce((total, item) => total + item.product.unitSalePrice * item.quantity, 0);
  };

  return (
    <CartContext.Provider value={{ 
      cart, 
      addToCart, 
      removeFromCart, 
      clearCart, 
      updateQuantity, 
      updatePrice, 
      getTotalPrice,
      openSnackbar, 
      snackbarMessage, 
      handleCloseSnackbar 
    }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};
