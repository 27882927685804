import React from "react";
import { Snackbar, SnackbarContent, Box, Typography, IconButton, Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import InfoIcon from "@mui/icons-material/Info";
import { NotificationType, NotificationTypes } from "../Enums/NotificationTypes";

interface NotificationSnackbarProps {
  message: string;
  isOpen: boolean;
  onClose: () => void;
  type: NotificationType;
}

const NotificationSnackbar: React.FC<NotificationSnackbarProps> = ({
  message,
  isOpen,
  onClose,
  type,
}) => {
  const getSnackbarStyles = () => {
    switch (type) {
      case NotificationTypes.SUCCESS:
        return {
          backgroundColor: "rgba(76, 175, 80, 0.9)", // Verde
          color: "white",
          icon: <CheckCircleIcon />,
        };
      case NotificationTypes.ERROR:
        return {
          backgroundColor: "rgba(244, 67, 54, 0.9)", // Rojo
          color: "white",
          icon: <ErrorIcon />,
        };
      case NotificationTypes.WARNING:
        return {
          backgroundColor: "rgba(255, 152, 0, 0.9)", // Naranja
          color: "black",
          icon: <WarningIcon />,
        };
      case NotificationTypes.INFO:
        return {
          backgroundColor: "rgba(33, 150, 243, 0.9)", // Azul
          color: "white",
          icon: <InfoIcon />,
        };
      default:
        return {
          backgroundColor: "rgba(158, 158, 158, 0.9)", // Gris
          color: "white",
          icon: null,
        };
    }
  };

  const { backgroundColor, color, icon } = getSnackbarStyles();

  return (
    <Snackbar
      open={isOpen}
      onClose={onClose}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      TransitionComponent={(props) => <Slide {...props} direction="left" />}
    >
      <SnackbarContent
        style={{
          backgroundColor,
          color,
          display: "flex",
          alignItems: "center",
          borderRadius: 8,
          padding: "16px",
          boxShadow: `0 0 10px 2px ${backgroundColor}`,
        }}
        message={
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            {icon && <Box sx={{ mr: 2, fontSize: "1.5rem" }}>{icon}</Box>}
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" sx={{ fontWeight: "bold", mb: 0.5 }}>
                {type === NotificationTypes.SUCCESS
                  ? "Éxito"
                  : type === NotificationTypes.ERROR
                  ? "Error"
                  : type === NotificationTypes.WARNING
                  ? "Advertencia"
                  : "Información"}
              </Typography>
              <Typography variant="body2">{message}</Typography>
            </Box>
          </Box>
        }
        action={
          <IconButton onClick={onClose} sx={{ color }}>
            <CloseIcon />
          </IconButton>
        }
      />
    </Snackbar>
  );
};

export default NotificationSnackbar;
