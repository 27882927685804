import React, { forwardRef, memo, Ref } from 'react';
import Box from '@mui/material/Box';
import SimpleBar from 'simplebar-react';

interface ScrollbarProps {
  children: React.ReactNode;
  sx?: React.CSSProperties; // Usamos React.CSSProperties para el tipo de sx
  [key: string]: any; // Para permitir propiedades adicionales
}

const Scrollbar = forwardRef<HTMLDivElement, ScrollbarProps>(
  ({ children, sx, openNav, onCloseNav, ...other }, ref: Ref<HTMLDivElement>) => {
    const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

    if (mobile) {
      return (
        <Box
          ref={ref}
          sx={{ overflow: 'auto', ...sx }}
          {...other}
        >
          {children}
        </Box>
      );
    }

    return (
      <Box
        ref={ref}
        sx={{ ...sx }}
        {...other}
      >
        <SimpleBar>
          {children}
        </SimpleBar>
      </Box>
    );
  }
);

export default memo(Scrollbar);
