import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { Application } from "../Models/ApplicationModel";
import applicationService from "../services/applicationService";
import { useNotification } from "./NotificationContext";
import { NotificationTypes } from "../Enums/NotificationTypes";
import { useLoader } from "./LoaderContext";

interface ApplicationsContextType {
  applicationsAll: Application[];
  addApplication: (application: Application) => void;
  updateApplication: (application: Application) => void;
  deleteApplication: (applicationId: number) => void;
}

const ApplicationsContext = createContext<ApplicationsContextType | null>(null);

export const ApplicationsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { showNotification } = useNotification();
  const { setLoading } = useLoader();

  const [applicationsAll, setApplicationsAll] = useState<Application[]>(
    localStorage.getItem('applicationsAll') ? JSON.parse(localStorage.getItem('applicationsAll')!) : []
  );

  useEffect(() => {
    localStorage.removeItem('applicationsAll');
    if (applicationsAll.length > 0) {
      localStorage.setItem('applicationsAll', JSON.stringify(applicationsAll));
    }
  }, [applicationsAll]);

  const addApplication = async (application: Application) => {
    try {
      setLoading(true);
      await applicationService.addApplication(application);
      fetchApplications();
      showNotification("Se ha agregado la aplicación correctamente.", NotificationTypes.SUCCESS);
    } catch (error) {
      console.error('Error fetching applications:', error);
      showNotification("Error al agregar la aplicación.", NotificationTypes.ERROR);
    } finally {
      setLoading(false);
    }
  };

  const updateApplication = async (updatedApplication: Application) => {    
    try {
      setLoading(true);
      await applicationService.updateApplication(updatedApplication);
      fetchApplications();
      showNotification("Se ha actualizado la aplicación correctamente.", NotificationTypes.SUCCESS);
    } catch (error) {
      console.error('Error fetching applications:', error);
      showNotification("Error al actualizar la aplicación.", NotificationTypes.ERROR);
    } finally {
      setLoading(false);
    }
  };

  const deleteApplication = async (applicationId: number) => {
    try {
      setLoading(true);
      await applicationService.deleteApplication(applicationId);
      fetchApplications();
      showNotification("Se ha eliminado la aplicación correctamente.", NotificationTypes.SUCCESS);
    } catch (error) {
      console.error('Error fetching applications:', error);
      showNotification("Error al eliminar la aplicación.", NotificationTypes.ERROR);
    } finally {
      setLoading(false);
    }
  };

  const fetchApplications = useCallback(async () => {
    try {
      setLoading(true);
      console.log("loading applicationsAll");
      const fetchedApplications = await applicationService.getApplications();
      setApplicationsAll(fetchedApplications);
      localStorage.setItem('applicationsAll', JSON.stringify(fetchedApplications));
    } catch (error) {
      console.error('Error fetching applications:', error);
      showNotification('Error al cargar las aplicaciones', NotificationTypes.ERROR);
    } finally {
      setLoading(false);
    }
  // eslint-disable-next-line
  }, []);

  
  useEffect(() => {
    const loadApplicationsIfEmpty = async () => {
      if (applicationsAll.length === 0) {
        console.log('ApplicationsAll está vacío, recuperando datos...');
        await fetchApplications();
      }
    };
    loadApplicationsIfEmpty();
  }, [applicationsAll, fetchApplications]);

  return (
    <ApplicationsContext.Provider value={{ applicationsAll, addApplication, updateApplication, deleteApplication }}>
      {children}
    </ApplicationsContext.Provider>
  );
};

export const useApplicationsContext = () => {
  const context = useContext(ApplicationsContext);
  if (!context) {
    throw new Error("useApplicationsContext must be used within an ApplicationsProvider");
  }
  return context;
};
