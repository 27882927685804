import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Button,
  Grid,
  useTheme,
  Box
} from "@mui/material";
import { useUserDataContext } from "../../context/UserDataContext";
import orderSaleService from "../../services/orderSalesService";
import { NotificationTypes } from "../../Enums/NotificationTypes";
import { useNotificationSnackbar } from "../../context/NotificationSnackbarContext";
import { getPaymentTypes } from "../../config/configUtil";

const CashRegisterModal: React.FC<{ isOpen: boolean; onClose: () => void; isClosing: boolean; }> = ({ isOpen, onClose, isClosing }) => {
  const theme = useTheme();
  const [openingBalance, setOpeningBalance] = useState<number | string>("");
  const [payments, setPayments] = useState<{ [key: string]: number }>({});
  const [additionalAmount, setAdditionalAmount] = useState<number>(0);
  const [totalSales, setTotalSales] = useState<number>(0);
  const [paymentBreakdown, setPaymentBreakdown] = useState<{ [key: string]: number }>({});
  const { cashRegisterApp, addCashRegisterApp, updateCashRegisterApp, getUserId, getUserAppId } = useUserDataContext();
  const { showNotificationSnackbar } = useNotificationSnackbar();
  const paymentTypes = getPaymentTypes();

  const totalPayments = Object.values(payments).reduce(
    (total, amount) => total + (amount || 0),
    0
  );

  const discrepancy = (totalSales + (cashRegisterApp?.openingBalance || 0)) - (totalPayments + additionalAmount);

  const [hasFetchedSalesData, setHasFetchedSalesData] = useState(false);

  useEffect(() => {
    if (isOpen && !hasFetchedSalesData) {
      const fetchSalesData = async () => {
        try {
          if (!cashRegisterApp?.id) {
            console.log('return fetchSalesData')
            return;
          } 
          const result = await orderSaleService.getTotalOrdersSale(Number(cashRegisterApp?.id));
          setTotalSales(result.totalSales);
          setPaymentBreakdown(result.paymentBreakdown);
          setHasFetchedSalesData(true);
          console.log('executing getTotalOrdersSale');
        } catch (error) {
          console.error("Error al obtener las ventas:", error);
        }
      };
      fetchSalesData();
    }
    // eslint-disable-next-line
  }, [isOpen]); // Ejecutar solo cuando el modal se abre // , hasFetchedSalesData, cashRegisterApp?.id

  const handlePaymentChange = (type: string, value: string) => {
    setPayments((prev) => ({
      ...prev,
      [type]: Number(value) || 0,
    }));
  };

  const handleRegister = () => {
    if (openingBalance === "" || Number(openingBalance) < 0) {
      showNotificationSnackbar("Por favor, ingrese un saldo inicial válido (0 o mayor).", NotificationTypes.WARNING);
      return;
    }
    addCashRegisterApp({
      openingBalance: Number(openingBalance),
      creationUser: getUserId(),
      appId: getUserAppId()
    });
    setOpeningBalance("");
    onClose();
  };

  const handleClose = () => {
    if (isClosing && discrepancy !== 0) {
      showNotificationSnackbar("No puedes cerrar la caja con un descuadre en los montos.", NotificationTypes.WARNING);
      return;
    }
    const request = {
      ...cashRegisterApp,
      updateUser: getUserId(),
      closingBalance: additionalAmount,
      totalSales: totalPayments,
      active: false
    };
    updateCashRegisterApp(request);
    onClose();
  };

  return (
    <Dialog open={isOpen} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ textAlign: "center" }}>
        {isClosing ? "Cierre de Caja" : "Apertura de Caja"}
      </DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(2) }}>
        {isClosing ? (
          <>
            <Typography sx={{ mb: theme.spacing(2) }}>
              Saldo inicial de la caja: <strong>{cashRegisterApp?.openingBalance || 0}</strong>
            </Typography>
            <Grid container spacing={2}>
              {paymentTypes.map((type) => (
                <Grid item xs={12} sm={6} key={type}>
                  <TextField
                    label={`Pago en ${type}`}
                    type="number"
                    value={payments[type] || ""}
                    onChange={(e) => handlePaymentChange(type, e.target.value)}
                    fullWidth
                    variant="outlined"
                    sx={{ marginBottom: theme.spacing(2) }}
                  />
                </Grid>
              ))}
            </Grid>
            <TextField
              label="Monto Adicional (+/-) Pagos realizados"
              type="number"
              value={additionalAmount}
              onChange={(e) => setAdditionalAmount(Number(e.target.value) || 0)}
              fullWidth
              variant="outlined"
              sx={{ mt: theme.spacing(2) }}
            />
            <Box sx={{ marginTop: theme.spacing(2) }}>
              <Typography>
                <strong>Total de pagos: </strong>{totalPayments}
              </Typography>
              <Typography>
                <strong>Total de ventas registradas: </strong>{totalSales}
              </Typography>
            </Box>
            <Typography sx={{ mt: theme.spacing(2) }}>
              <strong>Desglose de pagos:</strong>
              {paymentBreakdown && Object.keys(paymentBreakdown).map((key) => (
                <div key={key}>
                  {key}: <strong>{paymentBreakdown[key]}</strong>
                </div>
              ))}
            </Typography>
            <Typography
              sx={{
                mt: theme.spacing(1),
                color: discrepancy === 0 ? "success.main" : "error.main",
              }}
            >
              {discrepancy === 0
                ? "No hay descuadre en los montos."
                : `Descubre: ${
                    discrepancy > 0
                      ? `Faltan ${discrepancy}`
                      : `Sobra ${Math.abs(discrepancy)}`
                  }`}
            </Typography>
          </>
        ) : (
          <TextField
            label="Saldo Inicial"
            type="number"
            value={openingBalance}
            onChange={(e) => setOpeningBalance(e.target.value)}
            fullWidth
            variant="outlined"
            sx={{ mb: theme.spacing(2) }}
          />
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", padding: theme.spacing(2) }}>
        <Button onClick={onClose} variant="outlined" sx={{ width: "120px" }}>
          Cancelar
        </Button>
        <Button onClick={isClosing ? handleClose : handleRegister} variant="contained" color="primary" sx={{ width: "120px" }}>
          {isClosing ? "Cerrar Caja" : "Registrar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CashRegisterModal;
