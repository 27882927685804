import { Category } from "../Models/Category";
import { CATEGORY_API_URL } from "../config/apiConfig";
import { apiService } from "./apiService";

const categoryService = {
    getCategories: async (): Promise<Category[]> => {
        return await apiService(CATEGORY_API_URL, {
            method: 'GET',
            excludeAppId: true
        });
    },
    getCategoriesBySector: async (sectorsIds: number[]): Promise<Category[]> => {
        const url = `${CATEGORY_API_URL}/sectors?sectorIds=${sectorsIds.join('&sectorIds=')}`;
        return await apiService(url, {
            method: 'GET',
            excludeAppId: true
        });
    },
    addCategory: async (categoryReq: Category): Promise<Category> => {
        return await apiService(CATEGORY_API_URL, {
            method: 'POST',
            body: categoryReq,
            excludeAppId: true
        });
    },

    updateCategory: async (categoryReq: Category): Promise<Category> => {
        const url = `${CATEGORY_API_URL}/${categoryReq.id}`;
        return await apiService(url, {
            method: 'PUT',
            body: categoryReq,
            excludeAppId: true
        });
    },

    deleteCategory: async (id: number): Promise<void> => {
        const url = `${CATEGORY_API_URL}/${id}`;
        return await apiService(url, {
            method: 'DELETE',
            excludeAppId: true
        });
    },
}

export default categoryService;
