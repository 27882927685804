import React, { useEffect, lazy, Suspense } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import DashboardLayout from "./pages/DashboardLayout";
import getFilteredNavConfig from "./common/config-navigation";
import { UserDataProvider, useUserDataContext } from "./context/UserDataContext";
import { ThemeProvider } from "./context/ThemeContext";
import Loader from "./share/Loader";
import ProtectedRoute from "./components/ProtectedRoute";
const LoginPage = lazy(() => import("./pages/LoginPage"));
const HomePage = lazy(() => import("./pages/HomePage"));
// const Page404 = lazy(() => import("./pages/NotFoundPage"));

const AppContent: React.FC = () => {
  const { user } = useUserDataContext();
  const isLoggedIn = !!user?.code;
  const navigate = useNavigate();
  const navConfig = getFilteredNavConfig(user?.typeUserCodes);

  useEffect(() => {
    if (isLoggedIn && window.location.pathname === "/login") {
      navigate("/");
    } else if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn, navigate]);

  const handleLogin = () => {
    navigate("/");
  };

  const renderRoute = (item: any) => {
    const Component = item.component;
    const Providers = item.provider || [];

    const WrappedComponent = Providers.reduce(
      (child: any, Provider: any) => <Provider>{child}</Provider>,
      <Component />
    );

    return (
      <Route key={item.path} path={item.path} element={<ProtectedRoute>{WrappedComponent}</ProtectedRoute>} />
    );
  };
  return (
    <Routes>
      <Route path="/login" element={<LoginPage onLogin={handleLogin} />} />
      <Route element={<ProtectedRoute><DashboardLayout /></ProtectedRoute>}>
          <Route path="/" element={<HomePage />} />
          {navConfig.map((item) => renderRoute(item))}
        </Route>
      {/* <Route path="*" element={<Page404 />} /> */}
    </Routes>
  );
};

const App: React.FC = () => (
  <UserDataProvider>
    <ThemeProvider>
      <Suspense fallback={<Loader></Loader>}>
        <AppContent />
      </Suspense>
    </ThemeProvider>
  </UserDataProvider>
);

export default App;
