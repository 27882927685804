import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
  } from "react";
  import { useNotification } from "./NotificationContext";
  import { NotificationTypes } from "../Enums/NotificationTypes";
  import { Supplier } from "../Models/Supplier";
  import { useLoader } from "./LoaderContext";
  import supplierService from "../services/supplierService";
  
  interface SuppliersContextType {
    suppliersAll: Supplier[];
    getNameBySupplierId(supplierId: number): any;
    addSupplier: (supplier: Supplier) => void;
    updateSupplier: (supplier: Supplier) => void;
    deleteSupplier: (supplierId: number) => void;
  }
  
  const SuppliersContext = createContext<SuppliersContextType | null>(null);
  
  export const SuppliersProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
  }) => {
    const { showNotification } = useNotification();
    const { setLoading } = useLoader();

    const getNameBySupplierId = (supplierId: number) => {
      const supplier = suppliersAll?.find((x) => x.id === supplierId);
      return supplier ? supplier.name : supplierId;
    };
  
    const [suppliersAll, setSuppliersAll] = useState<Supplier[]>(
      localStorage.getItem("suppliersAll")
        ? JSON.parse(localStorage.getItem("suppliersAll")!)
        : []
    );
  
    useEffect(() => {
      localStorage.removeItem("suppliersAll");
      if (suppliersAll.length > 0) {
        localStorage.setItem("suppliersAll", JSON.stringify(suppliersAll));
      }
    }, [suppliersAll]);
  
    const addSupplier = async (supplier: Supplier) => {
      try {
        setLoading(true);
        await supplierService.addSupplier(supplier);
        fetchSuppliers();
        showNotification("Se ha agregado el proveedor correctamente.", NotificationTypes.SUCCESS);
      } catch (error) {
        console.error("Error agregando proveedor:", error);
        showNotification("Error al agregar el proveedor.", NotificationTypes.ERROR);
      } finally {
        setLoading(false);
      }
    };
  
    const updateSupplier = async (updatedSupplier: Supplier) => {
      try {
        setLoading(true);
        await supplierService.updateSupplier(updatedSupplier);
        fetchSuppliers();
        showNotification("Se ha actualizado el proveedor correctamente.", NotificationTypes.SUCCESS);
      } catch (error) {
        console.error("Error actualizando proveedor:", error);
        showNotification("Error al actualizar el proveedor.", NotificationTypes.ERROR);
      } finally {
        setLoading(false);
      }
    };
  
    const deleteSupplier = async (supplierId: number) => {
      try {
        setLoading(true);
        await supplierService.deleteSupplier(supplierId);
        fetchSuppliers();
        showNotification("Se ha eliminado el proveedor correctamente.", NotificationTypes.SUCCESS);
      } catch (error) {
        console.error("Error eliminando proveedor:", error);
        showNotification("Error al eliminar el proveedor.", NotificationTypes.ERROR);
      } finally {
        setLoading(false);
      }
    };
  
    const fetchSuppliers = useCallback(async () => {
      try {
        setLoading(true);
        console.log("loading suppliersAll");
        const fetchedSuppliers = await supplierService.getSuppliers();
        setSuppliersAll(fetchedSuppliers);
        localStorage.setItem("suppliersAll", JSON.stringify(fetchedSuppliers));
      } catch (error) {
        console.error("Error fetching suppliers:", error);
        showNotification("Error al cargar los proveedores", NotificationTypes.ERROR);
      } finally {
        setLoading(false);
      }
       // eslint-disable-next-line 
    }, []);
  
    useEffect(() => {
      const loadSuppliersIfEmpty = async () => {
        if (suppliersAll.length === 0) {
          console.log("suppliersAll está vacío, recuperando datos...");
          await fetchSuppliers();
        }
      };
      loadSuppliersIfEmpty();
      // eslint-disable-next-line 
    }, []);
  
    return (
      <SuppliersContext.Provider
        value={{
          suppliersAll,
          getNameBySupplierId,
          addSupplier,
          updateSupplier,
          deleteSupplier,
        }}
      >
        {children}
      </SuppliersContext.Provider>
    );
  };
  
  export const useSuppliersContext = () => {
    const context = useContext(SuppliersContext);
    if (!context) {
      throw new Error(
        "useSuppliersContext must be used within a SuppliersProvider"
      );
    }
    return context;
  };
  