import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  IconButton,
  Stepper,
  Step,
  StepLabel,
  useMediaQuery,
  Icon,
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useUserDataContext } from "../../context/UserDataContext";
import { useCart } from "../../context/CartContext";
import { ShoppingCartItem } from "../../Models/ShoppingCartItem";
import { OrderSaleDetail } from "../../Models/OrderSale";
import orderSaleService from "../../services/orderSalesService";
import { useNotification } from "../../context/NotificationContext";
import { NotificationTypes } from "../../Enums/NotificationTypes";
import { useLoader } from "../../context/LoaderContext";
import CartProductCard from "../../components/CartProductCard";
import { getPaymentTypes } from "../../config/configUtil";

const SaleConfirmationPopup: React.FC<{
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}> = ({ open, onClose, onSuccess }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [status, setStatus] = useState<"PAID" | "PENDING">("PAID");
  const [paymentMethod, setPaymentMethod] = useState("Efectivo");
  const [saleDate, setSaleDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const theme = useTheme();
  const { cashRegisterApp, getUserId, getUserAppId, getProducts } = useUserDataContext();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { cart, getTotalPrice, clearCart } = useCart();
  const { showNotification } = useNotification();
  const { setLoading } = useLoader();
  const [productDetails, setProductDetails] = useState<Record<number, any>>({});
  useEffect(() => {
    if (cart.length === 0) {
      onClose();
    }
  }, [cart, onClose]);

  useEffect(() => {
    const loadProductDetails = async () => {
      const details = await Promise.all(
        cart.map(async (item: ShoppingCartItem) => {
          const productDetail = await fetchProductDetails(
            Number(item.product.id)
          );

          if (productDetail.length > 0) {
            const productShopp = productDetail[0];
            const detalles: any = [];
            let cantidad = productShopp.quantity;
            productShopp.product.orderPurchaseDetails.forEach((lote) => {
              if (cantidad <= 0 || lote.availableStock <= 0) {
                return;
              }
              if (lote.availableStock < cantidad) {
                detalles.push({
                  id: lote.orderPurchaseId,
                  stock: lote.availableStock,
                  quantity: lote.availableStock,
                  unitSalePrice: lote.unitSalePrice,
                  unitPurchasePrice: lote.unitPurchasePrice,
                });
              } else {
                detalles.push({
                  id: lote.orderPurchaseId,
                  stock: lote.availableStock,
                  quantity: cantidad,
                  unitSalePrice: lote.unitSalePrice,
                  purchasePrice: lote.unitPurchasePrice,
                });
              }

              cantidad -= lote.availableStock;
            });

            return {
              id: productShopp.product.id,
              name: productShopp.product.name,
              description: productShopp.product.description,
              active: productShopp.product.active,
              details: detalles,
              category: productShopp.product.category,
              imageUrls: productShopp.product.imageUrls || [],
            };
          }
          return null;
        })
      );

      const filteredDetails = details.filter((detail) => detail !== null);

      setProductDetails(
        filteredDetails.reduce((acc: any, detail: any) => {
          acc[detail.id] = detail;
          return acc;
        }, {})
      );
    };

    loadProductDetails();
    // eslint-disable-next-line
  }, [cart]);

  const fetchProductDetails = async (productId: number) => {
    return cart.filter((x) => x.product.id === productId);
  };

  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  const calculateLotTotal = () => {
    if (!cart.length || !Object.keys(productDetails).length) {
      return 0;
    }

    return cart.reduce((acc, item: any) => {
      const productDetail = productDetails[Number(item.product.id)];
      let itemTotal = 0;

      if (productDetail?.details) {
        productDetail.details.forEach((prlo: any) => {
          const lotPrice = prlo.unitSalePrice * prlo.quantity;
          itemTotal += lotPrice;
        });
      } else {
        itemTotal = item.unitSalePrice * item.quantity;
      }
      return acc + itemTotal;
    }, 0);
  };

  const cartTotalLots = calculateLotTotal();
  const cartTotal = getTotalPrice();

  const getOrderDetails = () => {
    const orderSaleDetails: OrderSaleDetail[] = [];
    cart.forEach((item) => {
      const productDetail = productDetails[Number(item.product.id)];
      if (productDetail?.details) {
        productDetail.details.forEach((prlo: any) => {
          const orderDetail: OrderSaleDetail = {
            state: status,
            quantity: prlo.quantity,
            productId: Number(item.product.id),
            purchasePrice: prlo.unitPurchasePrice,
            salePrice: prlo.unitSalePrice,
            orderPurchaseId: prlo.id,
          };
          orderSaleDetails.push(orderDetail);
        });
      }
    });
    return orderSaleDetails;
  };

  const handleConfirm = async () => {
    const req: any = {
      totalAmount: cartTotalLots,
      state: status.toUpperCase(),
      paymentMethod: paymentMethod.toUpperCase(),
      saleDate: saleDate,
      cashRegisterId: Number(cashRegisterApp?.id),
      applicationId: getUserAppId(),
      userId: getUserId(),
      orderSaleDetails: getOrderDetails(),
      active: true,
    };
    try {
      setLoading(true);
      // no cambiar por el contexto porque está por encima su uso en el carrito 
      await orderSaleService.addOrder(req);
      clearCart();
      showNotification(
        "Venta realizada correctamente.",
        NotificationTypes.SUCCESS,
        1500
      );
      onSuccess();
      getProducts();
      onClose();
    } catch (error) {
      showNotification(
        "Error al realizar la venta. Por favor, intenta nuevamente.",
        NotificationTypes.ERROR
      );
    } finally {
      setLoading(false);
    }
  };

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <Box >
            {cart.map((item: ShoppingCartItem) => {
              const productDetail = productDetails[Number(item.product.id)];

              return (
                <CartProductCard
                  key={item.product.id}
                  item={item}
                  productDetails={productDetail}
                />
              );
            })}
          </Box>
        );
      case 1:
        return (
          <Box>
            <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
              <Button
                variant="contained"
                onClick={() => setStatus("PAID")}
                sx={{
                  backgroundColor:
                    status === "PAID" ? "green" : theme.palette.grey[300],
                  color: status === "PAID" ? "white" : "black",
                  borderRadius: "20px 0 0 20px",
                  px: 4,
                  "&:hover": {
                    backgroundColor:
                      status === "PAID"
                        ? "darkgreen"
                        : theme.palette.grey[400],
                  },
                  transform: "skewX(-20deg)",
                  transition: "all 0.3s ease",
                }}
              >
                Pagada
              </Button>
              <Button
                variant="contained"
                onClick={() => setStatus("PENDING")}
                sx={{
                  backgroundColor:
                    status === "PENDING" ? "green" : theme.palette.grey[300],
                  color: status === "PENDING" ? "white" : "black",
                  borderRadius: "0 20px 20px 0",
                  px: 4,
                  "&:hover": {
                    backgroundColor:
                      status === "PENDING"
                        ? "darkgreen"
                        : theme.palette.grey[400],
                  },
                  transform: "skewX(-20deg)",
                  transition: "all 0.3s ease",
                }}
              >
                Pendiente
              </Button>
            </Box>
            <TextField
              label="Fecha de Venta"
              type="date"
              fullWidth
              value={saleDate}
              onChange={(e) => setSaleDate(e.target.value)}
              sx={{ mb: 2 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Seleccione el método de pago
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 2,
                mb: 2,
                justifyContent: isMobile ? "center" : "flex-start",
              }}
            >
              {getPaymentTypes().map(
                (method) => (
                  <Card
                    key={method}
                    sx={{
                      width: isMobile ? "45%" : 150,
                      cursor: "pointer",
                      border:
                        paymentMethod === method
                          ? "2px solid green"
                          : "1px solid #ddd",
                      borderRadius: 2,
                      p: 2,
                      textAlign: "center",
                      position: "relative",
                      "&:hover": {
                        borderColor: theme.palette.primary.main,
                      },
                      backgroundColor: theme.palette.background.default,
                    }}
                    onClick={() => setPaymentMethod(method)}
                  >
                    <CardContent>
                      <Typography variant="body1">{method}</Typography>
                    </CardContent>
                    {paymentMethod === method && (
                      <Icon
                        component={CheckCircleIcon}
                        sx={{
                          color: "green",
                          position: "absolute",
                          top: 8,
                          right: 8,
                        }}
                      />
                    )}
                  </Card>
                )
              )}
            </Box>
            <TextField
              label="Busca un Cliente o agrega uno nuevo"
              variant="outlined"
              fullWidth
              sx={{ mb: 2 }}
            />
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" >
      <DialogTitle sx={{
        backgroundColor: theme.palette.background.default
      }}>
        Confirmación de Venta
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", right: 8, top: 8, }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box
        sx={{ position: "sticky", top: 0, zIndex: 2 }}
      >
        <Stepper activeStep={activeStep} alternativeLabel style={{backgroundColor: theme.palette.background.default}}>
          <Step>
            <StepLabel>Revisión de productos</StepLabel>
          </Step>
          <Step>
            <StepLabel>Detalles de pago</StepLabel>
          </Step>
        </Stepper>
      </Box>
      <DialogContent dividers sx={{ maxHeight: 600, overflowY: "auto", backgroundColor: theme.palette.background.default }}>
        {renderStepContent()}
      </DialogContent>

      <DialogActions
        sx={{ position: "sticky",
          bottom: 0,
          backgroundColor: theme.palette.background.default, 
          justifyContent: "space-between"
        }}
      >
        {activeStep > 0 && <Button onClick={handleBack}>Atrás</Button>}
        {activeStep < 1 ? (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "space",
                mr: 2,
              }}
            >
              {cartTotal !== cartTotalLots && (
                <Typography
                  variant="body2"
                  sx={{ color: "grey", textDecoration: "line-through" }}
                >
                  Subtotal (precio original): ${cartTotal.toFixed(2)}
                </Typography>
              )}
              <Typography
                variant="h6"
                sx={{ color: "green", fontWeight: "bold" }}
              >
                Total: ${cartTotalLots.toFixed(2)}
              </Typography>
            </Box>
            <Button onClick={handleNext} variant="contained" color="primary">
              Siguiente
            </Button>
          </>
        ) : (
          <Button onClick={handleConfirm} variant="contained" color="primary">
            Confirmar Venta
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default SaleConfirmationPopup;
