import React from 'react';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useTheme, useMediaQuery } from '@mui/material';
import CartItem from '../../components/CartItem';
import SaleConfirmationPopup from '../modal/SaleConfirmationPopup';
import { useCart } from '../../context/CartContext';
import { useNotification } from '../../context/NotificationContext';
import { NotificationTypes } from '../../Enums/NotificationTypes';

interface ShoppingCartProps {
  isOpen: boolean;
  onClose: () => void;
}


const ShoppingCart: React.FC<ShoppingCartProps> = ({ isOpen, onClose }) => {
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const { cart, getTotalPrice } = useCart();
  const { showNotification } = useNotification();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


  const handleClickOpen = () => {
    if (cart.length === 0) {
      showNotification(
        "Debe agregar por lo menos algún producto al carrito.",
        NotificationTypes.WARNING
      );
    } else {
      setOpenConfirm(true);
    }
  };


  const handleClose = () => {
    setOpenConfirm(false);
  };

  const handleSuccess = () => {
    onClose();
  };


  return (
    <>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        sx={{
          '& .MuiDrawer-paper': {
            width: isMobile ? '100%' : '50%',
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <Box sx={{ p: 2,
          position: 'relative', flexGrow: 1 ,
          backgroundColor: theme.palette.background.default}}>
          {isMobile && (
            <IconButton
              onClick={onClose}
              sx={{ position: 'absolute', top: 8, right: 8 }}
            >
              <CloseIcon />
            </IconButton>
          )}
          <Typography variant="h6" sx={{ mb: 2 }}>Carrito de Compras</Typography>

          <Box 
            sx={{
              height: '80vh',
              flexGrow: 1, 
              overflowY: 'auto', 
              border: '1px solid #ddd', 
              borderRadius: 1, 
              p: 2, 
              bgcolor: '#f9f9f9',
              backgroundColor: theme.palette.background.default
            }}
          >
            {cart.map((item: any) => (
              <CartItem
                key={item.product.id}
                id={item.product.id}
                name={item.product.name}
                price={item.product.unitSalePrice}
                stock={item.product.stock}
                quantity={item.quantity}
                stockMax={item.stock}
                image={item.product.imageUrls[0]}
                editPrice={false}
              />
            ))}
          </Box>

          <Box 
            sx={{ 
              mt: 2, 
              textAlign: 'right', 
              borderTop: '1px solid #ddd', 
              pt: 2 
            }}
          >
            <Typography variant="h6" sx={{ mb: 1 }}>
              Total: S/. {getTotalPrice().toFixed(2)}
            </Typography>
            <Button 
              variant="contained" 
              color="success" 
              startIcon={<AttachMoneyIcon />} 
              sx={{ width: '100%'}}
              onClick={handleClickOpen}
            >
              Pagar
            </Button>
            <SaleConfirmationPopup open={openConfirm} onClose={handleClose} onSuccess={handleSuccess} />
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default ShoppingCart;
