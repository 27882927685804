import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import RouteNavigator from "../routes/RouteNavigator";
import HeaderDashboard from "./Dashboard/HeaderDashboard";
import { Outlet } from "react-router-dom";
import UserSettings from "../components/User/UserSetting";
import { useUserDataContext } from "../context/UserDataContext";

interface DashboardLayoutProps {
  children?: React.ReactNode;
}

const useSessionTimeout = (onTimeout: () => void, timeoutDuration: number = 180 * 60 * 1000) => {
  useEffect(() => {
    let timer: NodeJS.Timeout;

    const resetTimer = () => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(onTimeout, timeoutDuration);
    };

    const events = ["mousemove", "keydown", "click", "scroll", "touchstart"];
    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    resetTimer();

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, resetTimer);
      });
      clearTimeout(timer);
    };
  }, [onTimeout, timeoutDuration]);
};

const DashboardLayout: React.FC<DashboardLayoutProps> = () => {
  const { showSettingUser, setShowSettingUser, onLogoutExpiredSesion } = useUserDataContext();
  const [openNav, setOpenNav] = useState<boolean>(false);

  const handleSessionTimeout = () => {
    onLogoutExpiredSesion();
  };

  useSessionTimeout(handleSessionTimeout);

  return (
    <>
      <HeaderDashboard openNav={() => setOpenNav(true)} />
      {!showSettingUser && (
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            flexDirection: { xs: "column", lg: "row" },
            overflowY: "auto",
          }}
        >
          <RouteNavigator openNav={openNav} onCloseNav={() => setOpenNav(false)} />
          <Box
            sx={{
              flexGrow: 1,
              pt: 2,
              pr: 2,
              pl: 2,
              maxWidth: "100%",
            }}
          >
            <Outlet />
          </Box>
        </Box>
      )}
      {showSettingUser && (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <UserSettings onClose={() => setShowSettingUser(false)} />
        </Box>
      )}
    </>
  );
};

export default DashboardLayout;
