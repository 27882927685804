import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Switch,
  Avatar,
  Grid,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  InputAdornment,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  ImageList,
  DialogActions,
  ImageListItem,
} from "@mui/material";
import { User } from "../../Models/User";
import userService from "../../services/userService";
import { useUserDataContext } from "../../context/UserDataContext";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ConfirmModal from "../../pages/modal/ConfirmModal";
import { useLoader } from "../../context/LoaderContext";

interface UserSettingsProps {
  onClose: () => void;
}

const avatarPaths = Array.from({ length: 25 }, (_, i) => `/assets/images/avatars/avatar_${i + 1}.jpg`);

const UserSettings: React.FC<UserSettingsProps> = ({ onClose }) => {
  const { user, categoriesAll, setActiveCategory, updateUserDetails, toggleDarkMode } = useUserDataContext();
  const { setLoading } = useLoader();
  const [editedUser, setEditedUser] = useState<User | null>(user);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(user?.urlimage || null);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openAvatarModal, setOpenAvatarModal] = useState(false);

  const toggleShowOldPassword = () => setShowOldPassword(!showOldPassword);
  const toggleShowNewPassword = () => setShowNewPassword(!showNewPassword);

  if (!user) {
    return (
      <Box sx={{ padding: 3, textAlign: "center" }}>
        <Typography variant="h6">No se encontró información del usuario.</Typography>
      </Box>
    );
  }

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setEditedUser((prev) => prev && { ...prev, [name]: value });
  };

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    setEditedUser((prev) => prev && { ...prev, [name]: !user?.darkMode });
    toggleDarkMode();
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAvatarSelect = (avatarUrl: string) => {
    setImagePreviewUrl(avatarUrl);
    setSelectedImage(null);
    setOpenAvatarModal(false);
  };

  const handleSave = async () => {
    if (editedUser) {
      let updatedUser: User = {
        ...editedUser,
        imageRequest: selectedImage? {
          images: [{
            urlimage: user.urlimage,
            name: selectedImage.name,
            imageBase64: imagePreviewUrl || "",
          }]
        } : undefined,
      };

     try {
      setLoading(true);
      var response = await userService.updateUser(updatedUser);
      updatedUser.urlimage = response;
      updateUserDetails(updatedUser)
      onClose();
     } catch (error) {
      console.log('Error en actualizacion de usuario', error);
     }finally {
      setLoading(false);
     }
    }
  };

  const formattedValue = (editedUser?.phone || "").slice(0, 9);

  const handleConfirmSave = () => {
    setOpenConfirmModal(true);
  };

  const handleConfirmAction = async () => {
    await handleSave();
    setOpenConfirmModal(false);
  };

  return (
    <Grid container spacing={3} sx={{ padding: 3 }}>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Avatar
                src={imagePreviewUrl || ""}
                alt={user?.name}
                sx={{
                  width: { xs: "100%", sm: 250, md: 300 },
                  height: { xs: "auto", sm: 250, md: 300 },
                  maxWidth: 300,
                  maxHeight: 300,
                  borderRadius: "50%",
                }}
              />
              <Button variant="outlined" component="label" sx={{ mt: 1 }}>
                Cambiar Imagen
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={handleImageChange}
                />
              </Button>
              <Button variant="contained" onClick={() => setOpenAvatarModal(true)}>
                Seleccionar Avatar
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12} md={8}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <TextField
                label="Nombre"
                name="name"
                value={editedUser?.name || ""}
                onChange={handleInputChange}
                fullWidth
              />
              <TextField
                label="Apellido"
                name="lastName"
                value={editedUser?.lastName || ""}
                onChange={handleInputChange}
                fullWidth
              />
              <TextField
                label="Antigua Contraseña"
                name="password"
                type={showOldPassword ? "text" : "password"}
                value={editedUser?.password || ""}
                onChange={handleInputChange}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={toggleShowOldPassword} edge="end">
                        {showOldPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Nueva Contraseña"
                name="newPassword"
                type={showNewPassword ? "text" : "password"}
                value={editedUser?.newPassword || ""}
                onChange={handleInputChange}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={toggleShowNewPassword} edge="end">
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Email"
                name="email"
                value={editedUser?.email || ""}
                onChange={handleInputChange}
                fullWidth
              />
              <TextField
                label="Teléfono"
                name="phone"
                type="number"
                value={formattedValue}
                onChange={handleInputChange}
                fullWidth
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={editedUser?.darkMode || false}
                    onChange={handleSwitchChange}
                    name="darkMode"
                  />
                }
                label="Modo Oscuro"
              />
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">Categorías disponibles</Typography>
              <Box
                sx={{
                  maxHeight: 200,
                  overflowY: "auto",
                  border: "1px solid #ccc",
                  borderRadius: 2,
                  padding: 1,
                }}
              >
                <List>
                  {categoriesAll?.map((category) => (
                    <ListItem key={category.id}>
                      <ListItemText primary={category.name} />
                      <ListItemSecondaryAction>
                        <Switch
                          checked={category.active}
                          onChange={() => setActiveCategory(category.id)}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: 2,
          flexWrap: "wrap",
        }}
      >
        <Button variant="outlined" onClick={onClose} sx={{ minWidth: 100 }}>
          Cancelar
        </Button>
        <Button variant="contained" onClick={handleConfirmSave} sx={{ minWidth: 100 }}>
          Guardar Cambios
        </Button>
      </Grid>
      <ConfirmModal
        open={openConfirmModal}
        onClose={() => setOpenConfirmModal(false)}
        onCancel={() => setOpenConfirmModal(false)}
        onConfirm={handleConfirmAction}
        title="Confirmar Cambios"
        message="¿Estás seguro de que deseas guardar los cambios realizados?"
        cancelButtonText="No, cancelar"
        confirmButtonText="Sí, guardar"
      />

      <Dialog open={openAvatarModal} onClose={() => setOpenAvatarModal(false)} fullWidth maxWidth="sm">
            <DialogTitle>Seleccionar Avatar</DialogTitle>
            <DialogContent>
              <ImageList cols={4} gap={10}>
                {avatarPaths.map((avatar, index) => (
                  <ImageListItem key={index}>
                    <img
                      src={avatar}
                      alt={`Avatar ${index + 1}`}
                      style={{ cursor: "pointer", borderRadius: "50%" }}
                      onClick={() => handleAvatarSelect(avatar)}
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenAvatarModal(false)}>Cerrar</Button>
            </DialogActions>
        </Dialog>
      </Grid>
  );
};

export default UserSettings;
