import { SECTOR_API_URL } from "../config/apiConfig";
import { Sector } from "../Models/Sector";
import { apiService } from "./apiService";


const sectorApplicationService = {
    getSectorApplication: async (): Promise<Sector[]> => {
        return await apiService(SECTOR_API_URL, {
            method: 'GET',
            excludeAppId: true
        });
    },

    addSectorApplication: async (categoryReq: Sector): Promise<void> => {
        return await apiService(SECTOR_API_URL, {
            method: 'POST',
            body: categoryReq,
            excludeAppId: true
        });
    },

    updateSectorApplication: async (categoryReq: Sector): Promise<void> => {
        const url = `${SECTOR_API_URL}/${categoryReq.id}`;
        return await apiService(url, {
            method: 'PUT',
            body: categoryReq,
            excludeAppId: true
        });
    },

    deleteSectorApplication: async (id: number): Promise<void> => {
        const url = `${SECTOR_API_URL}/${id}`;
        return await apiService(url, {
            method: 'DELETE',
            excludeAppId: true
        });
    },
}

export default sectorApplicationService;
