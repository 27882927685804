import React, { useMemo, ReactNode, createContext, useContext, useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider as MUIThemeProvider, ThemeOptions, Shadows } from '@mui/material/styles';
import { palette } from '../theme/palette';
import { typography } from '../theme/typography';
import { shadows } from '../theme/shadows';
import { overrides } from '../theme/overrides';
import { customShadows } from '../theme/customShadows';
import { useUserDataContext } from './UserDataContext';

interface ThemeContextType {
  theme: any;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const ThemeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const {user} = useUserDataContext();
  const [themeType, setThemeType] = useState<'light' | 'dark'>('light');

  useEffect(() => {
    if (user) {
      setThemeType(user.darkMode ? 'dark' : 'light');
    }
  }, [user]);

  const memoizedValue: ThemeOptions = useMemo(
    () => ({
      palette: palette(themeType === 'dark'),
      typography,
      shadows: shadows() as Shadows,
      customShadows: customShadows(),
      shape: { borderRadius: 8 },
    }),
    [themeType] 
  );

  const theme: any = useMemo(() => {
    const newTheme: any = createTheme(memoizedValue);
    newTheme.components = overrides(newTheme);  // Aplica los overrides
    return newTheme;
  }, [memoizedValue]);
  
  return (
    <ThemeContext.Provider  value={{ theme }}>
      <MUIThemeProvider theme={theme}>
      <CssBaseline />
      {children}
      </MUIThemeProvider>
    </ThemeContext.Provider>
  );
};


export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useThemeContext must be used within a ThemeProvider');
  }
  return context;
};