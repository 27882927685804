import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import { useUserDataContext } from "../../context/UserDataContext";
import Iconify from "../../components/Iconify";
import CashRegisterModal from "../modal/CashRegisterModal";

export default function CashRegisterPopover({ forceOpen }: { forceOpen?: boolean }) {
  const { cashRegisterApp, user } = useUserDataContext();
  const isCashOpen = !!cashRegisterApp?.id;
  const [isModalOpen, setModalOpen] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(false);

  useEffect(() => {
    if (cashRegisterApp?.id == null && user && isFirstTime === false) {
      setIsFirstTime(true);
      console.log('abriendo modal cashregister AUTOMATICO');
      handleModalOpen();
    }
  // eslint-disable-next-line
  }, [user]);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const handleCashRegisterToggle = () => {
    handleModalOpen();
  };

  return (
    <>
      <IconButton
        onClick={handleCashRegisterToggle}
        sx={{
          width: 50,
          height: 50,
          bgcolor: isCashOpen ? "success.main" : "error.main",
          color: isCashOpen ? "success.contrastText" : "error.contrastText",
          "&:hover": {
            bgcolor: isCashOpen ? "success.main" : "error.main",
          },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: 2,
        }}
        title={isCashOpen ? "Caja Abierta" : "Caja Cerrada"}
      >
        <Iconify icon={isCashOpen ? "mdi:cash-register" : "mdi:lock-outline"} />
      </IconButton>

      <CashRegisterModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        isClosing={isCashOpen} // Define si el modal se está usando para cerrar la caja
      />
    </>
  );
}
