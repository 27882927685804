import SvgColor from "../components/SvgColor";
import { lazy } from "react";
import { ApplicationsProvider } from "../context/ApplicationContext";
import { SectorsProvider } from "../context/SectorContext";
import { CategoriesProvider } from "../context/CategoryContext";
import { ProductProvider } from "../context/ProductContext";
import { TypeUsersProvider } from "../context/TypeUserContext";
import { OrderPurchasesProvider } from "../context/OrderPurchaseContext";
import { OrderSalesProvider } from "../context/OrderSaleContext";
import { ReportSalesProvider } from "../context/ReportSaleContext";
import { UserProvider } from "../context/UserContext";
import { SuppliersProvider } from "../context/SupplierContext";

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);
// Lazy load pages
const HomePage = lazy(() => import("../pages/HomePage"));
const UserManagementPage = lazy(() => import("../pages/Dashboard/UserManagementPage"));
const ProductManagementPage = lazy(() => import("../pages/Dashboard/ProductManagementPage"));
const OrderSaleManagementPage = lazy(() => import("../pages/Dashboard/OrderSaleManagementPage"));
const OrderPurchaseManagementPage = lazy(() => import("../pages/Dashboard/OrderPurchaseManagementPage"));
const SalesDashboard = lazy(() => import("../pages/Dashboard/SalesDashboard"));
const SectorApplicationManagementPage = lazy(() => import("../pages/Dashboard/SectorApplicationManagementPage"));
const CategoryManagementPage = lazy(() => import("../pages/Dashboard/CategoryManagementPage"));
const ApplicationManagementPage = lazy(() => import ("../pages/Dashboard/ApplicationManagementPage"));

const initialNavConfig = [
  {
    title: 'Inicio',
    path: '/',
    icon: icon('ic_home'),
    component: HomePage,
  },
  {
    title: 'Usuarios',
    path: '/users',
    icon: icon('ic_add_user'),
    component: UserManagementPage,
    provider: [ UserProvider, ApplicationsProvider, TypeUsersProvider ],
    roles: ["ADMIN"],
  },
  {
    title: "Applicaciones",
    path: "/applications",
    icon: icon("ic_category"),
    component: ApplicationManagementPage,
    provider: [ ApplicationsProvider, SectorsProvider ],
    roles: ["ADMIN"],
  },
  {
    title: 'Rubros',
    path: '/sectors',
    icon: icon('ic_packaging_category'),
    component: SectorApplicationManagementPage,
    provider: [ SectorsProvider, CategoriesProvider ],
    roles: ["ADMIN"],
  },
  {
    title: 'Categorias',
    path: '/category',
    icon: icon('ic_category'),
    component: CategoryManagementPage,
    provider: [ CategoriesProvider ],
    roles: ["ADMIN","MANAGER"],
  },
  {
    title: 'Productos',
    path: '/products',
    icon: icon('ic_products_apple'),
    component: ProductManagementPage,
    provider: [ ProductProvider, CategoriesProvider ],
    roles: ["ADMIN","MANAGER"],
  },
  {
    title: 'Compras',
    path: '/orderPurchase',
    icon: icon('ic_buy_product'),
    provider: [ OrderPurchasesProvider, SuppliersProvider,ProductProvider, CategoriesProvider ],
    component: OrderPurchaseManagementPage,
    roles: ["ADMIN", "MANAGER"]
  },
  {
    title: 'Ventas',
    path: '/orderSale',
    icon: icon('ic_sale'),
    provider: [ OrderSalesProvider ],
    component: OrderSaleManagementPage,
    roles: ["ADMIN", "MANAGER"]
  },
  {
    title: 'Reportes',
    path: '/reports',
    icon: icon('ic_analytics'),
    provider: [ ReportSalesProvider ],
    component: SalesDashboard,
    roles: ["ADMIN", "MANAGER"]
  },
];
const getFilteredNavConfig = (userRoles: any) => {
  if(!userRoles) {
    return []
  }
  return initialNavConfig.filter(
    (item: any) => !item?.roles || item?.roles.some((role: any) => userRoles.includes(role))
  );
};
export default getFilteredNavConfig;
