import { Option } from "../Models/Option";
const irrelevantWords = new Set([
  "el", "la", "los", "las", "de", "del", "en", "y", "a", "con", "por", "para", "un", "una", "unos", "unas", 
  "al", "o", "que", "se", "su", "sus", "es", "son", "este", "estos", "esta", "estas", "eso", "esa", "esas"
])

export function mapToOptionsDescription(lst: any[]): Option[] {
  if (lst.length === 0) return [];

  return lst.map((cat: any) => ({
    value: String(cat.id),
    description: cat.description,
  }));
}

export function mapToOptionsName(lst: any[]): Option[] {
  if (lst.length === 0) return [];

  return lst.map((cat: any) => ({
    value: String(cat.id),
    description: cat.name,
  }));
}

export function delay(time: number, callback: () => void): void {
  setTimeout(callback, time);
}

export function getRandomInt(max: number): number {
  return Math.floor(Math.random() * (max + 1));
}

export function generateUUID(): string {
  return ([1e7] as any + -1e3 + -4e3 + -8e3 + -1e11)
    .replace(/[018]/g, (c: any) => {
      const r = crypto.getRandomValues(new Uint8Array(1))[0] & 15;
      const v = (c === '8' ? ((r & 0x3) | 0x8) : r);
      return v.toString(16);
    });
}

export function getRandomPrice(min: number, max: number, precision: number): number {
  const scale = Math.pow(10, precision);
  return Math.round((Math.random() * (max - min) + min) * scale) / scale;
}

export function base64ToByteArray(base64String: string): Uint8Array {
  const binaryString = atob(base64String.split(',')[1]); // Decodificar la parte base64
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}

export function getFromLocalStorage <T,>(key: string, defaultValue: T): T {
  const storedValue = localStorage.getItem(key);
  return storedValue ? JSON.parse(storedValue) : defaultValue;
};

export function setToLocalStorage(key: string, value: any) {
  localStorage.setItem(key, JSON.stringify(value));
};

export const cleanText = (text: string): string[] => {
  return text
    .toLowerCase()
    .normalize("NFD")
    .replace(/[^a-zA-Záéíóúüñ\s]/g, "") // Solo permite letras comunes y espacios
    .split(/\s+/)
    .filter(word => word.length > 2 && !irrelevantWords.has(word));
};

export const levenshtein = (a: string, b: string): number => {
  const matrix = Array.from({ length: a.length + 1 }, (_, i) =>
    Array.from({ length: b.length + 1 }, (_, j) => (i === 0 ? j : j === 0 ? i : 0))
  );

  for (let i = 1; i <= a.length; i++) {
    for (let j = 1; j <= b.length; j++) {
      matrix[i][j] =
        a[i - 1] === b[j - 1]
          ? matrix[i - 1][j - 1]
          : Math.min(matrix[i - 1][j], matrix[i][j - 1], matrix[i - 1][j - 1]) + 1;
    }
  }

  return matrix[a.length][b.length];
};

export function deepMerge<T extends object>(target: T, source: Partial<T>): T {
  if (!source) return target;

  for (const key of Object.keys(source)) {
    const targetValue = target[key as keyof T];
    const sourceValue = source[key as keyof T];

    if (
      typeof targetValue === "object" &&
      targetValue !== null &&
      typeof sourceValue === "object" &&
      sourceValue !== null
    ) {
      // Si ambos valores son objetos, hacer merge recursivo
      target[key as keyof T] = deepMerge({ ...targetValue }, sourceValue);
    } else if (sourceValue !== undefined) {
      // Si no, simplemente sobrescribimos el valor
      target[key as keyof T] = sourceValue as any;
    }
  }

  return target;
}
