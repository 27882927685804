import React from "react";
import { Dialog, DialogContent, Box, Typography, IconButton } from "@mui/material";
import { NotificationType } from "../Enums/NotificationTypes";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import InfoIcon from "@mui/icons-material/Info";

interface NotificationModalProps {
  message: string;
  isOpen: boolean;
  onClose: () => void;
  type: NotificationType;
}

const NotificationModal: React.FC<NotificationModalProps> = ({
  message,
  isOpen,
  onClose,
  type,
}) => {
  const getAlertStyles = () => {
    switch (type) {
      case "success":
        return {
          bgcolor: "rgba(76, 175, 80, 0.9)",
          color: "white",
          icon: <CheckCircleIcon />,
          borderColor: "green",
        }; // Verde
      case "error":
        return {
          bgcolor: "rgba(244, 67, 54, 0.9)",
          color: "white",
          icon: <ErrorIcon />,
          borderColor: "red",
        }; // Rojo
      case "warning":
        return {
          bgcolor: "rgba(255, 152, 0, 0.9)",
          color: "black",
          icon: <WarningIcon />,
          borderColor: "orange",
        }; // Naranja
      case "info":
        return {
          bgcolor: "rgba(33, 150, 243, 0.9",
          color: "white",
          icon: <InfoIcon />,
          borderColor: "blue",
        }; // Azul
      default:
        return {
          bgcolor: "rgba(158, 158, 158, 0.9)",
          color: "white",
          icon: null,
          borderColor: "gray",
        }; // Gris
    }
  };

  const { bgcolor, color, icon, borderColor } = getAlertStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 5,
          overflow: "hidden",
          transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
          animation: `${isOpen ? "fade-in" : "fade-out"} 0.5s ease-out`,
          border: `2px solid ${borderColor}`,
          background: bgcolor,
          boxShadow: `0 0 20px 2px ${borderColor}`,
        },
      }}
    >
      <DialogContent
        sx={{
          p: 3,
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          color,
          borderRadius: 2,
          opacity: 0.9,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {icon && <Box sx={{ mr: 2 }}>{icon}</Box>}
          <Box>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 1 }}>
              {type === "success"
                ? "Éxito"
                : type === "error"
                ? "Error"
                : type === "warning"
                ? "Advertencia"
                : "Información"}
            </Typography>
            <Typography variant="body1">{message}</Typography>
          </Box>
        </Box>
        <IconButton onClick={onClose} sx={{ color }}>
          <CloseIcon />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

export default NotificationModal;
