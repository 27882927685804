import React, { createContext, useContext, useState, ReactNode } from "react";
import { NotificationType } from "../Enums/NotificationTypes";
import NotificationSnackbar from "../share/NotificationSnackBar";

interface NotificationContextType {
    showNotificationSnackbar: (message: string, type: NotificationType, time?: number | null) => void;
}

const NotificationSnackbarContext = createContext<NotificationContextType | undefined>(undefined);

export const NotificationSnackbarProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [message, setMessage] = useState<string | null>(null);
  const [type, setType] = useState<NotificationType | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const showNotificationSnackbar = (message: string, type: NotificationType, time: number | null = 3000) => {
    setMessage(message);
    setType(type);
    setIsOpen(true);

    if (time) {
      setTimeout(() => handleClose(), time);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setMessage(null);
    setType(null);
  };

  return (
    <NotificationSnackbarContext.Provider value={{ showNotificationSnackbar }}>
      {children}
      {message && type && (
        <NotificationSnackbar
          message={message}
          isOpen={isOpen}
          onClose={handleClose}
          type={type}
        />
      )}
    </NotificationSnackbarContext.Provider>
  );
};

export const useNotificationSnackbar = () => {
  const context = useContext(NotificationSnackbarContext);
  if (!context) {
    throw new Error("useNotification must be used within a NotificationProvider");
  }
  return context;
};
