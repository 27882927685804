import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme, styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { useResponsive } from '../../hooks/use-responsive';
import { HEADER, NAV } from '../../common/config-layout';
import { bgBlur } from '../../theme/css';
import Iconify from '../../components/Iconify';
import LanguagePopover from '../../common/language-popover';
import NotificationsPopover from '../View/notification-popover';
import AccountPopover from '../View/account-popover';
import ShoppingCart from '../View/ShoppingCart';
import ShoppingCartButton from '../View/ShoppingCartButton';
import ThemePopover from '../../common/themePopover';
import CashRegisterPopover from '../View/CashRegister-popover';
import { useUserDataContext } from '../../context/UserDataContext';
interface HeaderProps {
  openNav: () => void;
}

const HeaderDashboard: React.FC<HeaderProps> = ({ openNav }) => {
  const { cashRegisterApp }= useUserDataContext();
  const [isModalOpen, setModalOpen] = useState(false);
  const theme = useTheme();

  const CustomAppBar = styled(AppBar)(() => ({
    boxShadow: 'none',
    height: HEADER.H_MOBILE,
    zIndex: theme.zIndex.appBar + 1,
    ...bgBlur({
      color: theme.palette.background.default,
    }),
    transition: theme.transitions.create(['height'], {
      duration: theme.transitions.duration.shorter,
    }),
    ...(theme.breakpoints.up('lg') && {
      width: `calc(100% - ${NAV.WIDTH + 26}px)`,
      height: HEADER.H_DESKTOP,
    }),
  }));

  const lgUp = useResponsive('up', 'lg');
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = (open: boolean) => {
    setDrawerOpen(open);
  };

  const renderContent = (
    <Stack direction="row" alignItems="center" spacing={1}>
      <CashRegisterPopover forceOpen={isModalOpen} />
      <ThemePopover />
      <LanguagePopover />
      <NotificationsPopover />
      <ShoppingCartButton
        onClick={() => {
          if (!cashRegisterApp?.id) {
            setModalOpen(true);
            return;
          }
          handleDrawerToggle(true);
        }}
      />
      <ShoppingCart
        isOpen={drawerOpen}
        onClose={() => handleDrawerToggle(false)}
      />
      <AccountPopover />
    </Stack>
  );
  
  return (
    <CustomAppBar>
      <Toolbar
        sx={{
          height: '100%',
          px: { lg: 5 },
        }}
      >
        {!lgUp && (
          <IconButton onClick={openNav} sx={{ mr: 1 }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButton>
        )}
        <Box sx={{ flexGrow: 1 }} />
        {renderContent}
      </Toolbar>
    </CustomAppBar>
  );
};

export default HeaderDashboard;
