import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";
import { Product } from "../Models/Product";
import { Category } from "../Models/Category";
import productService from "../services/productService";
import categoryService from "../services/categoryService";
import { NotificationTypes } from "../Enums/NotificationTypes";
import { useNotification } from "./NotificationContext";
import { getFromLocalStorage, setToLocalStorage } from "../utils/utils";
import { Application } from "../Models/ApplicationModel";
import { User } from "../Models/User";
import authService from "../services/authService";
import { useLoader } from "./LoaderContext";
import { useCart } from "./CartContext";
import { useNavigate } from "react-router-dom";
import { CashRegister } from "../Models/CashRegisterModel";
import cashRegisterService from "../services/cashRegisterService";
import { Sector } from "../Models/Sector";
interface UserContextType {
  user: User | null;
  products: Product[];
  productsAll: Product[];
  categoriesAll: Category[];
  showSettingUser: boolean;
  cashRegisterApp: CashRegister | null;
  getNameByUserId(userId: number): any;
  getProductById: (productId: number) => Promise<Product | undefined>;
  getUserId: () => number;
  getUserAppId: () => number;
  getSectorsApp: () => Sector[] | undefined;
  getInfoApp: () => Application | undefined;
  getProducts: () => Promise<Product[]>;
  getUserCategories: () => Promise<Category[] | null>;
  setProductsAll: React.Dispatch<React.SetStateAction<Product[]>>,
  setCategoriesAll: React.Dispatch<React.SetStateAction<Category[]>>,
  setGlobalUser: React.Dispatch<React.SetStateAction<User | null>>;
  setShowSettingUser: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveCategory: (id: number) => void;
  toggleDarkMode: () => void;
  onLogoutLogin: () => void;
  onLogoutExpiredSesion: () => void;
  clearProducts: () => void;
  updateUserDetails: (user: User) => void;
  setCashRegisterApp: React.Dispatch<React.SetStateAction<CashRegister | null>>;
  addCashRegisterApp: (cashRegister: CashRegister) => void;
  updateCashRegisterApp: (cashRegister: CashRegister) => void;
}

const UserDataContext = createContext<UserContextType | null>(null);

interface UserProviderProps {
  children: ReactNode;
}

export const UserDataProvider: React.FC<UserProviderProps> = ({ children }) => {
  const { showNotification } = useNotification();
  const { setLoading } = useLoader();
  const {clearCart } = useCart();
  const Navigate = useNavigate();

  const [key, setKey] = useState(0);
  const [user, setGlobalUser] = useState<User | null>( getFromLocalStorage("userData", null));
  const [products, setProducts] = useState<Product[]>(getFromLocalStorage('userProductsData', []));
  const [productsAll, setProductsAll] = useState<Product[]>(getFromLocalStorage('userProductsAll', []));
  const [categoriesAll, setCategoriesAll] = useState<Category[]>(getFromLocalStorage('userCategoriesData', []));
  const [showSettingUser, setShowSettingUser] = useState<boolean>( getFromLocalStorage("showSettingUser", false));
  const [cashRegisterApp, setCashRegisterApp] = useState<CashRegister | null>(getFromLocalStorage("cashRegisterData", null));
  const [initializeApplication, setInitializeApplication] = useState<boolean>(false);
  const [initializeAllProduct, setInitializeAllProduct] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      setToLocalStorage("userData", user);
    } else {
      localStorage.removeItem("userData");
    }
  }, [user]);

  useEffect(() => {
    if (categoriesAll?.length > 0) {
      setToLocalStorage("userCategoriesData", categoriesAll);
    } else {
      localStorage.removeItem("userCategoriesData");
    }
  }, [categoriesAll]);

  useEffect(() => {
    if (cashRegisterApp) {
      setToLocalStorage("cashRegisterData", cashRegisterApp);
    } else {
      localStorage.removeItem("cashRegisterData");
    }
  }, [cashRegisterApp]);


  useEffect(() => setToLocalStorage("userProductsData", products), [products]);
  useEffect(() => setToLocalStorage("userProductsAll", productsAll), [productsAll]);
  useEffect(() => setToLocalStorage("showSettingUser", showSettingUser), [showSettingUser]);

  const getNameByUserId = (idUser: number) => user?.id === idUser ? user?.name : idUser;
  const getUserApplications = () => user?.userApplications?.[0];
  const getUserId = () => user?.id ?? 0;
  const getUserAppId = () => getUserApplications()?.appId ?? 0;
  const getInfoApp = () => getUserApplications()?.app;
  const getSectorsApp = () => getInfoApp()?.sectors;
  const resetContext = () => {setKey((prevKey) => prevKey + 1)};
  const getProductById = async (productId: number): Promise<Product | undefined> => { const product = productsAll.find((p) => p.id === productId);  return product; };

  const toggleDarkMode = () => {
    setGlobalUser((prevUser) =>
      prevUser ? { ...prevUser, darkMode: !prevUser.darkMode } : prevUser
    );
  };

  useEffect(() => {
    const loadUserData = async () => {
        if(!initializeApplication) {
          if (user) {
            try {
              setLoading(true);
              console.log('Loading user data')
              await Promise.all([getProducts(), getUserCategories(), fetchCashRegisterApp()]);
              setInitializeApplication(true);
            } catch (error) {
              console.error("Error loading user data:", error);
              showNotification("Error al cargar los datos del usuario.", NotificationTypes.ERROR);
            } finally {
              setLoading(false);
            }
          }
        }
    };
    loadUserData();
    // eslint-disable-next-line 
  }, [user])

  useEffect(() => {
    const loaderUserProductsAll = async () => {
      if(!initializeAllProduct) {
        if(categoriesAll?.length > 0){
          try {
            setLoading(true);
            console.log('Loading user data products')
            await getProductsAll();
            setInitializeAllProduct(true);
          } catch (error) {
            console.error("Error loading user data:", error);
            showNotification("Error al cargar los todos los productos del usuario.", NotificationTypes.ERROR);
          } finally {
            setLoading(false);
          }
        }
      }
    }
    loaderUserProductsAll();
    // eslint-disable-next-line
  }, [categoriesAll])

  const getUserCategories = async (): Promise<Category[]> => {
    const sectors = getSectorsApp();
    if (sectors) {
      const sectorsIds = sectors.map(x => x.id);
      if (sectorsIds && sectorsIds?.length > 0) {
        try {
          setLoading(true);
          console.log("loading userCategories");
          const fetchedCategories = await categoryService.getCategoriesBySector(
            sectorsIds.map((x) => Number(x))
          );
          const updatedCategories = fetchedCategories.map((category) => ({
            ...category,
            active: true,
          }));

          setCategoriesAll(updatedCategories);
          localStorage.setItem( "userCategoriesData", JSON.stringify(updatedCategories));
          return updatedCategories;
        } catch (error) {
          console.error("Error fetching categories:", error);
          showNotification("Error al cargar las categorías",NotificationTypes.ERROR);
        } finally {
          setLoading(false);
        }
      }
    }
    return categoriesAll ?? [];
  };

  const setActiveCategory = (id: number) => {  
    if(categoriesAll?.length > 0) {
      setCategoriesAll((prevCategories) =>  
        prevCategories ? prevCategories.map((category) =>  
          category.id === id ? { ...category, active: !category.active } : category  
        ) : []
      );  
    }
    
  };

  const getProducts = async (): Promise<Product[]> => {
    try {
      setLoading(true);
      console.log("loading products");
      const fetchedProducts = await productService.getProducts();
      setProducts(fetchedProducts);
      localStorage.setItem('userProductsData', JSON.stringify(fetchedProducts));
      return fetchedProducts;
    } catch (error) {
      console.error('Error fetching products:', error);
      showNotification('Error al cargar los productos', NotificationTypes.ERROR);
    } finally {
      setLoading(false);
    }
    return products;
  };

  const getProductsAll = async (): Promise<Product[]> => {
    if(categoriesAll?.length > 0) {
      try {
        setLoading(true);
        console.log("loading productsAll");
        const fetchedProductsAll = await productService.getAllProductsByCategory(categoriesAll.map(x => x.id));
        setProductsAll(fetchedProductsAll);
        localStorage.setItem('userProductsAll', JSON.stringify(fetchedProductsAll));
        return fetchedProductsAll;
      } catch (error) {
        console.error('Error fetching userProductsAll:', error);
        showNotification('Error al cargar los userProductsAll', NotificationTypes.ERROR);
      } finally {
        setLoading(false);
      }
    }
    return productsAll;
  };

  const fetchCashRegisterApp = async (): Promise<CashRegister | null> => {
    try {
      setLoading(true);
      console.log("loading fetchCashRegisterApp");
      const fetchedCashRegisterAll = await cashRegisterService.getCashRegister();
      setCashRegisterApp(fetchedCashRegisterAll);
      localStorage.setItem('cashRegisterData', JSON.stringify(fetchedCashRegisterAll));
      return fetchedCashRegisterAll;
    } catch (error) {
      console.error('Error fetching fetchCashRegisterApp:', error);
      showNotification('Error al cargar los fetchCashRegisterApp', NotificationTypes.ERROR);
    } finally {
      setLoading(false);
    }
    return cashRegisterApp;
  }

  const addCashRegisterApp = async (cashRegister: CashRegister) => {
    try {
      setLoading(true);
      await cashRegisterService.addCashRegister(cashRegister);
      fetchCashRegisterApp();
      showNotification(
        "Se ha creado la Caja de registro correctamente.",
        NotificationTypes.SUCCESS
      );
    } catch (error) {
      console.error("Error creando Caja de registro:", error);
      showNotification("Error al crear la caja de Registro.", NotificationTypes.ERROR);
    } finally {
      setLoading(false);
    }
  };

  const updateCashRegisterApp = async (cashRegister: CashRegister) => {
    try {
      setLoading(true);
      await cashRegisterService.updateCashRegister(cashRegister);
      fetchCashRegisterApp();
      showNotification(
        "Se ha Cerrado la Caja de registro correctamente.",
        NotificationTypes.SUCCESS
      );
    } catch (error) {
      console.error("Error Cerrando la Caja de registro:", error);
      showNotification(
        "Error al Cerrar la Caja de registro.",
        NotificationTypes.ERROR
      );
    } finally {
      setLoading(false);
    }
  };
   
  const updateUserDetails = async (updatedData: Partial<User>) => {
    if (!user) {
      showNotification("No hay un usuario autenticado para actualizar.", NotificationTypes.WARNING);
      return;
    }
  
    // Generar el objeto con los datos actualizados
    const updatedUser: Partial<User> = {
      id: user.id,
      name: updatedData.name ?? user.name,
      lastName: updatedData.lastName ?? user.lastName,
      email: updatedData.email ?? user.email,
      phone: updatedData.phone ?? user.phone,
      darkMode: updatedData.darkMode ?? user.darkMode,
      urlimage: updatedData.urlimage ?? user.urlimage,
    };
  
    try {
      setGlobalUser((prevUser) => ({
        ...prevUser!,
        ...updatedUser,
      }));
  
      showNotification("Datos del usuario actualizados correctamente.", NotificationTypes.SUCCESS);
    } catch (error) {
      console.error("Error al actualizar los datos del usuario:", error);
      showNotification("Error al actualizar los datos del usuario.", NotificationTypes.ERROR);
    }
  };

  const clearProducts = () => {
    setProducts([]);
    setProductsAll([]);
    setCategoriesAll([]);
  }

  const onLogoutExpiredSesion = () => {
    showNotification("Tu sesión ha expirado debido a inactividad.", NotificationTypes.WARNING, null);
    onLogoutLogin();
  }

  const onLogoutLogin = () =>{
    setShowSettingUser(false);
    setGlobalUser(null);
    clearProducts();
    clearCart();
    resetContext();
    authService.logout();
    setInitializeAllProduct(false);
    setInitializeApplication(false);
    Navigate("/login");
  }

  return (
    <UserDataContext.Provider
      value={{
        user,
        products,
        productsAll,
        categoriesAll,
        showSettingUser,
        cashRegisterApp,
        getNameByUserId,
        getProductById,
        getUserId,
        getUserAppId,
        getSectorsApp,
        getInfoApp,
        getProducts,
        getUserCategories,
        setProductsAll,
        setCategoriesAll,
        setGlobalUser,
        setShowSettingUser,
        setActiveCategory,
        toggleDarkMode,
        clearProducts,
        onLogoutExpiredSesion,
        onLogoutLogin,
        updateUserDetails,
        setCashRegisterApp,
        addCashRegisterApp,
        updateCashRegisterApp,
      }}
    >
      <div key={key}>{children}</div>
    </UserDataContext.Provider>
  );
};

// Hook personalizado para usar el contexto del usuario
export const useUserDataContext = (): UserContextType => {
  const context = useContext(UserDataContext);
  if (!context) {
    throw new Error("useUserContext debe usarse dentro de un UserProvider");
  }
  return context;
};
