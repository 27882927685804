import { OrderSale, OrderSaleCashClosing } from "../Models/OrderSale";
import { ORDER_SALES_API_URL } from "../config/apiConfig";
import { apiService } from "./apiService";

const orderSaleService = {
    getOrders: async (startDate?: string, endDate?: string): Promise<OrderSale[]> => {
        const queryParams: Record<string, string> = {};
        if (startDate) {
            queryParams.startDate = startDate;
        }
        if (endDate) {
            queryParams.endDate = endDate;
        }
        return await apiService(ORDER_SALES_API_URL,{
            queryParams: queryParams
        });
    },

    getTotalOrdersSale: async ( cashRegisterId: number): Promise<OrderSaleCashClosing> => {
        const url = `${ORDER_SALES_API_URL}/total/${cashRegisterId}`;
        return await apiService(url, {
            method: 'GET'
        });
    },

    addOrder: async (orderReq: OrderSale): Promise<void> => {
        const url = `${ORDER_SALES_API_URL}`;
        return await apiService(url, {
            method: 'POST',
            body: orderReq,
          })
    },

    updateOrder: async (orderReq: OrderSale): Promise<void> => {
        const url = `${ORDER_SALES_API_URL}/${orderReq.id}`;
        return await apiService(url, {
            method: 'PUT',
            body: orderReq,
            excludeAppId: true
        });
    },

    deleteOrder: async (id: number): Promise<void> => {
        const url = `${ORDER_SALES_API_URL}/${id}`;
        return await apiService(url, {
            method: 'DELETE',
            excludeAppId: true
        })
    },
    payOrderSale: async (idOrderSale: number): Promise<void> => {
        const url = `${ORDER_SALES_API_URL}/pay/${idOrderSale}`;
        return await apiService(url, {
            method: 'PUT',
            excludeAppId: true
        });
    },
    payOrderSaleDetail: async (idOrderDetailSale: number): Promise<void> => {
        const url = `${ORDER_SALES_API_URL}/payDetail/${idOrderDetailSale}`;
        return await apiService(url, {
            method: 'PUT',
            excludeAppId: true
        });
    },
};

export default orderSaleService;
