import { User } from "../Models/User";
import { parseDateToString } from "../utils/dateUtil";

interface ApiConfig {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  body?: any;
  headers?: Record<string, string>;
  excludeAppId?: boolean;
  addAuditFields?: boolean;
  queryParams?: Record<string, string | number | boolean>;
}

export const apiService = async (endpoint: string, config: ApiConfig = {}) => {
  const storedUser = localStorage.getItem('userData');
  const user: User = storedUser ? JSON.parse(storedUser) : null;
  const idApplication = user?.userApplications?.[0]?.appId ?? 0;

  // Construir query params si existen
  const queryParamsString = config.queryParams
    ? '?' +
      Object.entries(config.queryParams)
        .filter(([_, value]) => value !== undefined && value !== null)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&')
    : '';

  // Construir URL con query params y appId
  const url = config.excludeAppId
    ? `${endpoint}${queryParamsString}`
    : `${endpoint}/${idApplication}${queryParamsString}`;

  const defaultHeaders = {
    'Content-Type': 'application/json',
  };

  let bodyWithAudit = config.body;
  if (config.addAuditFields && bodyWithAudit && (config.method === 'POST' || config.method === 'PUT')) {
    bodyWithAudit = {
      ...bodyWithAudit,
      ...(config.method === 'POST' && {
        creationUser: user?.id.toString() ?? null,
        creationDate: parseDateToString(new Date()),
      }),
      ...(config.method === 'PUT' && {
        updateUser: user?.id.toString() ?? null,
        updateDate: parseDateToString(new Date()),
      }),
    };
  }

  const finalConfig: RequestInit = {
    method: config.method || 'GET',
    headers: {
      ...defaultHeaders,
      ...config.headers,
    },
    body: config.body ? JSON.stringify(bodyWithAudit) : null,
  };

  try {
    const response = await fetch(url, finalConfig);

    if (!response.ok) {
      const errorMessage = await response?.text();
      throw new Error(`Failed to fetch: ${errorMessage}`);
    }

    const contentType = response.headers.get('Content-Type');
    if (contentType && contentType.includes('application/json')) {
      return await response.json();
    }

    return await response.text();
  } catch (error) {
    console.error('Error in API request:', error);
    throw error;
  }
};