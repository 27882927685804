import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNotification } from "./NotificationContext";
import { NotificationTypes } from "../Enums/NotificationTypes";
import { OrderPurchase } from "../Models/OrderPurchase";
import { useLoader } from "./LoaderContext";
import orderPurchaseService from "../services/orderPurchasesService";

interface OrderPurchasesContextType {
  orderPurchasesAll: OrderPurchase[];
  addOrderPurchase: (orderPurchase: OrderPurchase) => void;
  updateOrderPurchase: (orderPurchase: OrderPurchase) => void;
  deleteOrderPurchase: (orderPurchaseId: number) => void;
  fetchOrderPurchases: (startDate?: string, endDate?: string, active?: string ) => void;
}

const OrderPurchasesContext = createContext<OrderPurchasesContextType | null>(null);

export const OrderPurchasesProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { showNotification } = useNotification();
  const { setLoading } = useLoader();

  const [orderPurchasesAll, setOrderPurchasesAll] = useState<OrderPurchase[]>(
    localStorage.getItem("orderPurchasesAll") ? JSON.parse(localStorage.getItem("orderPurchasesAll")!) : []
  );

  useEffect(() => {
    localStorage.removeItem("orderPurchasesAll");
    if (orderPurchasesAll.length > 0) {
      localStorage.setItem("orderPurchasesAll", JSON.stringify(orderPurchasesAll));
    }
  }, [orderPurchasesAll]);

  const addOrderPurchase = async (orderPurchase: any) => {
    try {
      setLoading(true);
      await orderPurchaseService.addOrder(orderPurchase);
      fetchOrderPurchases();
      showNotification("Se ha agregado la compra correctamente.", NotificationTypes.SUCCESS);
    } catch (error) {
      console.error("Error agregando compra:", error);
      showNotification("Error al agregar la compra.", NotificationTypes.ERROR);
    } finally {
      setLoading(false);
    }
  };

  const updateOrderPurchase = async (updatedOrderPurchase: OrderPurchase) => {
    try {
      setLoading(true);
      await orderPurchaseService.updateOrder(updatedOrderPurchase);
      fetchOrderPurchases();
      showNotification("Se ha actualizado la compra correctamente.", NotificationTypes.SUCCESS);
    } catch (error) {
      console.error("Error actualizando compra:", error);
      showNotification("Error al actualizar la compra.", NotificationTypes.ERROR);
    } finally {
      setLoading(false);
    }
  };

  const deleteOrderPurchase = async (orderPurchaseId: number) => {
    try {
      setLoading(true);
      await orderPurchaseService.deleteOrder(orderPurchaseId);
      fetchOrderPurchases();
      showNotification("Se ha eliminado la compra correctamente.", NotificationTypes.SUCCESS);
    } catch (error) {
      console.error("Error eliminando compra:", error);
      showNotification("Error al eliminar la compra.", NotificationTypes.ERROR);
    } finally {
      setLoading(false);
    }
  };

  const fetchOrderPurchases = useCallback(async (startDate?: string, endDate?: string) => {
    try {
      setLoading(true);
      console.log("loading orderPurchasesAll");
      const fetchedOrderPurchases = await orderPurchaseService.getOrders(startDate, endDate);
      setOrderPurchasesAll(fetchedOrderPurchases);
      localStorage.setItem("orderPurchasesAll", JSON.stringify(fetchedOrderPurchases));
    } catch (error) {
      console.error("Error fetching order purchases:", error);
      showNotification("Error al cargar las compras", NotificationTypes.ERROR);
    } finally {
      setLoading(false);
    }
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const loadOrderPurchasesIfEmpty = async () => {
      console.log("orderPurchasesAll, recuperando datos...");
      await fetchOrderPurchases();
    };
    loadOrderPurchasesIfEmpty();
  // eslint-disable-next-line
  }, []);

  return (
    <OrderPurchasesContext.Provider
      value={{ orderPurchasesAll, addOrderPurchase, updateOrderPurchase, deleteOrderPurchase, fetchOrderPurchases }}>
      {children}
    </OrderPurchasesContext.Provider>
  );
};

export const useOrderPurchasesContext = () => {
  const context = useContext(OrderPurchasesContext);
  if (!context) {
    throw new Error("useOrderPurchasesContext must be used within an OrderPurchasesProvider");
  }
  return context;
};
