import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
  } from "react";
  import { useNotification } from "./NotificationContext";
  import { NotificationTypes } from "../Enums/NotificationTypes";
  import { User } from "../Models/User";
  import userService from "../services/userService";
  import { useLoader } from "./LoaderContext";
  
  interface UserContextType {
    usersAll: User[];
    addUser: (user: User) => void;
    updateUser: (user: User) => void;
    deleteUser: (userId: number) => void;
  }
  
  const UserContext = createContext<UserContextType | null>(null);
  
  export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
  }) => {
    const { showNotification } = useNotification();
    const { setLoading } = useLoader();
  
    const [usersAll, setUsersAll] = useState<User[]>(
      localStorage.getItem("usersAll") ? JSON.parse(localStorage.getItem("usersAll")!) : []
    );
  
    useEffect(() => {
      localStorage.removeItem("usersAll");
      if (usersAll.length > 0) {
        localStorage.setItem("usersAll", JSON.stringify(usersAll));
      }
    }, [usersAll]);
  
    const addUser = async (user: User) => {
      try {
        setLoading(true);
        await userService.addUser(user);
        fetchUsers();
        showNotification("Se ha agregado el usuario correctamente.", NotificationTypes.SUCCESS);
      } catch (error) {
        console.error("Error agregando usuario:", error);
        showNotification("Error al agregar el usuario.", NotificationTypes.ERROR);
      } finally {
        setLoading(false);
      }
    };
  
    const updateUser = async (updatedUser: User) => {
      try {
        setLoading(true);
        await userService.updateUser(updatedUser);
        fetchUsers();
        showNotification("Se ha actualizado el usuario correctamente.", NotificationTypes.SUCCESS);
      } catch (error) {
        console.error("Error actualizando usuario:", error);
        showNotification("Error al actualizar el usuario.", NotificationTypes.ERROR);
      } finally {
        setLoading(false);
      }
    };
  
    const deleteUser = async (userId: number) => {
      try {
        setLoading(true);
        await userService.deleteUser(userId);
        fetchUsers();
        showNotification("Se ha eliminado el usuario correctamente.", NotificationTypes.SUCCESS);
      } catch (error) {
        console.error("Error eliminando usuario:", error);
        showNotification("Error al eliminar el usuario.", NotificationTypes.ERROR);
      } finally {
        setLoading(false);
      }
    };
  
    const fetchUsers = useCallback(async () => {
      try {
        setLoading(true);
        console.log("loading usersAll");
        const fetchedUsers = await userService.getUsers();
        setUsersAll(fetchedUsers);
        localStorage.setItem("usersAll", JSON.stringify(fetchedUsers));
      } catch (error) {
        console.error("Error fetching users:", error);
        showNotification("Error al cargar los usuarios", NotificationTypes.ERROR);
      } finally {
        setLoading(false);
      }
      // eslint-disable-next-line 
    }, []);
  
    useEffect(() => {
      const loadUsersIfEmpty = async () => {
        if (usersAll.length === 0) {
          console.log("usersAll está vacío, recuperando datos...");
          await fetchUsers();
        }
      };
      loadUsersIfEmpty();
      // eslint-disable-next-line 
    }, []);
  
    return (
      <UserContext.Provider
        value={{
          usersAll,
          addUser,
          updateUser,
          deleteUser,
        }}
      >
        {children}
      </UserContext.Provider>
    );
  };
  
  export const useUserContext = () => {
    const context = useContext(UserContext);
    if (!context) {
      throw new Error("useUserContext must be used within a UserProvider");
    }
    return context;
  };
  