import React, { createContext, useContext, useState, ReactNode } from 'react';
import NotificationModal from '../share/NotificationModal';
import { NotificationType } from '../Enums/NotificationTypes';
import { delay } from '../utils/utils';

interface NotificationContextType {
  showNotification: (message: string, type: NotificationType, time?: number | null) => void;
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

export const NotificationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [message, setMessage] = useState<string | null>(null);
  const [type, setType] = useState<NotificationType | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const showNotification = (message: string, type: NotificationType, time: number | null = 3000) => {
    setMessage(message);
    setType(type);
    setIsOpen(true);
    if(time) {
      delay(time, () => handleClose());
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setMessage(null);
    setType(null);
  };

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      {children}
      {message && type && (
        <NotificationModal
          message={message}
          isOpen={isOpen}
          onClose={handleClose}
          type={type}
        />
      )}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};
