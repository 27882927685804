import React, {
    createContext,
    useContext,
    useEffect,
    useState,
  } from "react";
  import { useNotification } from "./NotificationContext";
  import { NotificationTypes } from "../Enums/NotificationTypes";
  import { useLoader } from "./LoaderContext";
  import { SalesReportResponse } from "../Models/SalesReportResponse";
  import reportService from "../services/reportService";
  
  interface ReportSalesContextType {
    reportSalesAll: SalesReportResponse[];
    refreshForceReportSale: () => void;
  }
  
  const ReportSalesContext = createContext<ReportSalesContextType | null>(null);
  
  export const ReportSalesProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
  }) => {
    const { showNotification } = useNotification();
    const { setLoading } = useLoader();
  
    const [reportSalesAll, setReportSalesAll] = useState<SalesReportResponse[]>(
      localStorage.getItem("reportSalesAll")
        ? JSON.parse(localStorage.getItem("reportSalesAll")!)
        : []
    );
  
    useEffect(() => {
      localStorage.removeItem("reportSalesAll");
      if (reportSalesAll.length > 0) {
        localStorage.setItem("reportSalesAll", JSON.stringify(reportSalesAll));
      }
    }, [reportSalesAll]);
  
    const fetchReportSales = async () => {
      try {
        setLoading(true);
        console.log("loading reportSalesAll");
        const fetchedReportSales = await reportService.getSalesReport();
        setReportSalesAll(fetchedReportSales);
        localStorage.setItem("reportSalesAll", JSON.stringify(fetchedReportSales));
      } catch (error) {
        console.error("Error fetching report sales:", error);
        showNotification("Error al cargar los reportes de ventas", NotificationTypes.ERROR);
      } finally {
        setLoading(false);
      }
    };
  
    const refreshForceReportSale = async () => {
      try {
        setLoading(true);
        console.log("forcing refresh of reportSalesAll");
        const refreshedReportSales = await reportService.getSalesReport();
        setReportSalesAll(refreshedReportSales);
        localStorage.setItem("reportSalesAll", JSON.stringify(refreshedReportSales));
        showNotification("Los reportes de ventas se han actualizado correctamente.", NotificationTypes.SUCCESS);
      } catch (error) {
        console.error("Error forzando la actualización de reportes de ventas:", error);
        showNotification("Error al forzar la actualización de reportes de ventas", NotificationTypes.ERROR);
      } finally {
        setLoading(false);
      }
    };
  
    useEffect(() => {
      const loadReportSalesIfEmpty = async () => {
        if (reportSalesAll.length === 0) {
          console.log("reportSalesAll está vacío, recuperando datos...");
          await fetchReportSales();
        }
      };
      loadReportSalesIfEmpty();
    // eslint-disable-next-line
    }, []);
  
    return (
      <ReportSalesContext.Provider value={{ reportSalesAll, refreshForceReportSale }}>
        {children}
      </ReportSalesContext.Provider>
    );
  };
  
  export const useReportSalesContext = () => {
    const context = useContext(ReportSalesContext);
    if (!context) {
      throw new Error("useReportSalesContext must be used within a ReportSalesProvider");
    }
    return context;
  };
  