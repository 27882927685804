import { forwardRef, useState } from "react";
import { Link, Box, Stack, Button, useTheme } from "@mui/material";
import RouterLink from "../routes/RouterLink";
import NoImage from "../assets/images/no-image.svg";
import { useUserDataContext } from "../context/UserDataContext";

interface LogoProps {
  disabledLink?: boolean;
  sx?: object;
}

const LogoApplication = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const theme = useTheme();
    const { getInfoApp } = useUserDataContext();

    const [infoApp] = useState(getInfoApp());
    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: "70%",
          height: "70px",
          display: "inline-flex",
          ...sx,
          marginTop: "5px",
          alignItems: "center",
        }}
        {...other}
      >
        <img
          src={infoApp?.urlImage || NoImage}
          alt={infoApp?.description || "Logo de la aplicación"}
          style={{
            width: "60px",
            height: "60px",
            borderRadius: "10px",
            objectFit: "contain",
          }}
          onError={(e) => {
            if (e.currentTarget.src !== NoImage) {
              e.currentTarget.src = NoImage;
            }
          }}
        />

        <Stack
          alignItems="center"
          spacing={3}
          sx={{ borderRadius: 2, position: "relative", marginLeft: "5px" }}
        >
          <Button
            variant="contained"
            color="inherit"
            sx={{ backgroundColor: theme.palette.background.default }}
          >
            {infoApp?.code}
          </Button>
        </Stack>
      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href="/" sx={{ display: "contents" }}>
        {logo}
      </Link>
    );
  }
);

export default LogoApplication;
