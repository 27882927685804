import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import { Tooltip, useTheme } from "@mui/material";
import { useCart } from "../context/CartContext";

interface CartItemProps {
  id: number;
  name: string;
  price: number;
  stock: number;
  quantity: number;
  stockMax: number;
  image: string;
  editPrice: boolean;
}

const CartItem: React.FC<CartItemProps> = ({
  id,
  name,
  price,
  stock,
  quantity,
  image,
  editPrice = false,
}) => {
  const [isEditingPrice, setIsEditingPrice] = useState<boolean>(false);
  const subtotal = (price * quantity).toFixed(2);
  const theme = useTheme();
  const { updateQuantity, updatePrice, removeFromCart } = useCart();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        mb: 2,
        p: 2,
        borderRadius: 1,
        border: "1px solid #ddd",
        backgroundColor: theme.palette.background.default,
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
          alignItems: "flex-start",
        },
      }}
    >
      <IconButton
        onClick={() => removeFromCart(id)}
        sx={{
          mr: 2,
          color: "#d32f2f",
          [theme.breakpoints.down("sm")]: { alignSelf: "flex-start" },
        }}
      >
        <DeleteIcon />
      </IconButton>
      <Box
        component="img"
        src={image}
        alt={name}
        sx={{
          width: 70,
          height: 110,
          borderRadius: 1,
          objectFit: "cover",
          mr: 2,
          [theme.breakpoints.down("sm")]: {
            width: "100%",
            height: "auto",
            mb: 1,
          },
        }}
      />
      <Box sx={{ flexGrow: 1, width: "100%" }}>
        <Tooltip title={name}>
          <Typography
            variant="body1"
            sx={{
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {name}
          </Typography>
        </Tooltip>
        <Typography variant="body2" color="textSecondary">
          Stock: {stock} disponibles
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "60px",
            mt: 1,
            [theme.breakpoints.down("sm")]: { mt: 0 },
          }}
        >
          {isEditingPrice ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                onClick={() => setIsEditingPrice(false)}
                sx={{ mr: 1, color: "#d32f2f" }}
              >
                <CancelIcon />
              </IconButton>
              <TextField
                value={price.toFixed(2)}
                onChange={(e) => updatePrice(id, parseFloat(e.target.value))}
                type="number"
                inputProps={{ min: 0, step: "0.01" }}
                sx={{ width: "100px", textAlign: "right !important" }}
              />
            </Box>
          ) : editPrice ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton onClick={() => setIsEditingPrice(true)} sx={{ mr: 1 }}>
                <EditIcon />
              </IconButton>
              <Typography
                variant="body1"
                sx={{ width: "100px", textAlign: "right" }}
              >
                $ {price.toFixed(2)}
              </Typography>
            </Box>
          ) : (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="body1"
                sx={{ width: "100px", textAlign: "right" }}
              >
                $ {price.toFixed(2)}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          ml: 2,
          width: "100%",
          [theme.breakpoints.down("sm")]: {
            flexDirection: "row",
            justifyContent: "space-between",
            mt: 1,
            ml: 0,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 1,
            [theme.breakpoints.down("sm")]: { mb: 0 },
          }}
        >
          <IconButton
            onClick={() => updateQuantity(id, quantity - 1, stock)}
            disabled={quantity <= 0}
          >
            <RemoveIcon />
          </IconButton>
          <TextField
            value={quantity}
            onChange={(e) =>
              updateQuantity(id, parseInt(e.target.value, 10), stock)
            }
            type="number"
            inputProps={{ min: 1, max: stock }}
            sx={{
              width: 50,
              textAlign: "center",
              [theme.breakpoints.down("sm")]: { width: "60px" },
            }}
          />
          <IconButton
            onClick={() => updateQuantity(id, quantity + 1, stock)}
            disabled={quantity >= stock}
          >
            <AddIcon />
          </IconButton>
        </Box>
        <Typography variant="body2" color="textSecondary">
          Subtotal: $ {subtotal}
        </Typography>
      </Box>
    </Box>
  );
};

export default CartItem;
