const API_BASE_URL = "https://portalventa.com/api";
// const API_BASE_URL =  'https://localhost:7015/api'

export const USER_API_URL = `${API_BASE_URL}/users`;
export const PRODUCT_API_URL = `${API_BASE_URL}/product`;
export const CATEGORY_API_URL = `${API_BASE_URL}/category`;
export const ORDER_SALES_API_URL = `${API_BASE_URL}/ordersale`;
export const ORDER_PURCHASES_API_URL = `${API_BASE_URL}/orderpurchase`;
export const SUPPLIERS_API_URL = `${API_BASE_URL}/supplier`;
export const REPORT_API_URL = `${API_BASE_URL}/report`;
export const APPLICATION_URL = `${API_BASE_URL}/application`;
export const SECTOR_API_URL = `${API_BASE_URL}/sector`;
export const CASH_REGISTER_API_URL = `${API_BASE_URL}/cashregister`;

export const MAX_PRODUCT_ID = 9999999;
