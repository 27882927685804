import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { LoaderProvider } from "./context/LoaderContext";
import { NotificationProvider } from "./context/NotificationContext";
import { HelmetProvider } from "react-helmet-async";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Snackbar } from "@mui/material";
import { CartProvider, useCart } from "./context/CartContext";
import { NotificationSnackbarProvider } from "./context/NotificationSnackbarContext";

const container = document.getElementById("root");

const SnackbarWrapper: React.FC = () => {
  const { openSnackbar, snackbarMessage, handleCloseSnackbar } = useCart();

  return (
    <Snackbar
      open={openSnackbar}
      autoHideDuration={3000}
      onClose={handleCloseSnackbar}
      message={snackbarMessage}
    />
  );
};

if (container) {
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <Router>
        <HelmetProvider>
          <LoaderProvider>
            <NotificationProvider>
              <NotificationSnackbarProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <CartProvider>
                    <App />
                    <SnackbarWrapper />
                  </CartProvider>
                </LocalizationProvider>
              </NotificationSnackbarProvider>
            </NotificationProvider>
          </LoaderProvider>
        </HelmetProvider>
      </Router>
    </React.StrictMode>
  );
}
