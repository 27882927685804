import React, { forwardRef } from 'react';
import Box from '@mui/material/Box';
import { Icon } from '@iconify/react';

interface IconifyProps {
  icon: string; // Puedes ajustar el tipo aquí si usas iconos que no sean de tipo string
  width?: number;
  sx?: React.CSSProperties;
  [key: string]: any; // Para permitir otros props no especificados
}

const Iconify = forwardRef<HTMLDivElement, IconifyProps>(
  ({ icon, width = 20, sx, ...other }, ref) => (
    <Box
      ref={ref}
      component={Icon}
      className="component-iconify"
      icon={icon}
      sx={{ width, height: width, ...sx }}
      {...other}
    />
  )
);

export default Iconify;
