import React, { createContext, ReactNode, useContext } from 'react';
import productService from '../services/productService';
import { Product } from '../Models/Product';
import { useNotification } from './NotificationContext';
import { NotificationTypes } from '../Enums/NotificationTypes';
import { useLoader } from './LoaderContext';
import { useUserDataContext } from './UserDataContext';

interface ProductContextType {
  productsAll: Product[];
  addProduct: (newProduct: Product) => Promise<void>;
  addProductReturn: (newProduct: Product) => Promise<Product>;
  updateProduct: (updatedProduct: Product) => Promise<void>;
  deleteProduct: (productId: number) => Promise<void>;
}

const ProductContext = createContext<ProductContextType | null>(null);

export const ProductProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { showNotification } = useNotification();
  const { setLoading } = useLoader();
  const { productsAll, setProductsAll } = useUserDataContext();

  const addProduct = async (product: Product) => {
    try {
      setLoading(true);
      const productAdd = await productService.addProductWithImage(product);
      setProductsAll((prev) => [productAdd, ...prev]); // Actualizamos productos en UserDataContext
      showNotification("Se ha agregado el producto correctamente.", NotificationTypes.SUCCESS);
    } catch (error) {
      console.error("Error agregando producto:", error);
      showNotification("Error al agregar el producto.", NotificationTypes.ERROR );
    } finally {
      setLoading(false);
    }
  };

  const addProductReturn = async (product: Product): Promise<Product> => {
    try {
      setLoading(true);
      const productAdd = await productService.addProductWithImage(product);
      setProductsAll((prev) => [productAdd, ...prev]); // Actualizamos productos en UserDataContext
      showNotification("Se ha agregado el producto correctamente.", NotificationTypes.SUCCESS);
      return productAdd;
    } catch (error) {
      console.error("Error agregando producto:", error);
      showNotification("Error al agregar el producto.", NotificationTypes.ERROR);
      throw error; // Lanzamos el error para que quien llame la función pueda manejarlo
    } finally {
      setLoading(false);
    }
  };

  const updateProduct = async (product: Product) => {
    try {
      setLoading(true);
      const productUpdate = await productService.updateProductWithImage(product);
      setProductsAll((prev) => prev.map((p) => (p.id === productUpdate.id ? productUpdate : p))); 
      showNotification("Se ha actualizado el producto correctamente.", NotificationTypes.SUCCESS);
    } catch (error) {
      console.error("Error actualizando producto:", error);
      showNotification("Error al actualizar el producto.", NotificationTypes.ERROR );
    } finally {
      setLoading(false);
    }
  };

  const deleteProduct = async (productId: number) => {
    try {
      setLoading(true);
      await productService.deleteProduct(productId);
      setProductsAll((prev) => prev.filter((p) => p.id !== productId)); 
      showNotification("Se ha eliminado el producto correctamente.", NotificationTypes.SUCCESS);
    } catch (error) {
      console.error("Error eliminando producto:", error);
      showNotification("Error al eliminar el producto.", NotificationTypes.ERROR);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ProductContext.Provider value={{ productsAll, addProduct, addProductReturn, updateProduct, deleteProduct }}>
      {children}
    </ProductContext.Provider>
  );
};


export const useProductContext = () => {
  const context = useContext(ProductContext);
  if (!context) {
    throw new Error('useProductContext must be used within a ProductProvider');
  }
  return context;
};
