import { Supplier } from "../Models/Supplier";
import { SUPPLIERS_API_URL } from "../config/apiConfig";
import { apiService } from "./apiService";

const supplierService = {
    getSuppliers: async (): Promise<Supplier[]> => {
        return await apiService(SUPPLIERS_API_URL, {
            method: 'GET',
            excludeAppId: true
        });
    },

    addSupplier: async (supplierReq: Supplier): Promise<void> => {
        return await apiService(SUPPLIERS_API_URL, {
            method: 'POST',
            body: supplierReq,
            excludeAppId: true
        });
    },

    updateSupplier: async (supplierReq: Supplier): Promise<void> => {
        const url = `${SUPPLIERS_API_URL}/${supplierReq.id}`;
        return await apiService(url, {
            method: 'PUT',
            body: supplierReq,
            excludeAppId: true
        });
    },

    deleteSupplier: async (id: number): Promise<void> => {
        const url = `${SUPPLIERS_API_URL}/${id}`;
        return await apiService(url, {
            method: 'DELETE',
            excludeAppId: true
        });
    },
};

export default supplierService;
