import { User } from '../Models/User';
import { UserLogin } from '../Models/UserLogin';
import { USER_API_URL } from '../config/apiConfig';

const authService = {
    login: async (user: UserLogin): Promise<User> => {
        try {
            const response = await fetch(`${USER_API_URL}/Login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(user),
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(errorText || 'Failed to login.');
            }
            const responseData = response.json();
            localStorage.setItem('userData', JSON.stringify(responseData));
            return await responseData;
        } catch (error) {
            throw new Error('Failed to login: Inauthorized');
        }
    },
    
    logout: async () => {
        localStorage.clear();        
    },

    getUserData: async () => {
        const userData = localStorage.getItem('userData');
        return userData ? JSON.parse(userData) : null;
    }
}

export default authService;
