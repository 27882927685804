import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

interface ConfirmModalPopupProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  onClose: () => void;
  title?: string;
  message?: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
}

const ConfirmModal: React.FC<ConfirmModalPopupProps> = ({
  open,
  onClose,
  onCancel,
  onConfirm,
  title = "Confirmar Acción",
  message = "¿Estás seguro de que deseas continuar?",
  cancelButtonText = "Cancelar",
  confirmButtonText = "Confirmar",
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          {cancelButtonText}
        </Button>
        <Button onClick={onConfirm} color="primary">
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
