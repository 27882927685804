
import productManagementPageConfig from '../constants/productManagementPageConfig.json';
import CategoryManagementPageConfig from '../constants/CategoryManagementPageConfig.json';
import SectorManagementPageConfig from '../constants/SectorApplicationManagemetPageConfig.json';
import ApplicationManagementPageConfig from '../constants/ApplicationManagementPageConfig.json';
import PaymentTypes from '../constants/PaymentTypes.json'

import { Field } from '../Models/Field';

export const getTableProductsHeaders = (): Field[] => {
  return productManagementPageConfig.complete.headers as Field[];
};

export const getTableProductsFields = (simple: boolean = false): Field[] => {
  return (simple ? productManagementPageConfig.simple.fields : productManagementPageConfig.complete.fields) as Field[];
};

export const getTableSectorApplicationHeaders = (): Field[] => {
  return SectorManagementPageConfig.headers as Field[];
};

export const getTableSectorApplicationFields = (): Field[] => {
  return SectorManagementPageConfig.fields as Field[];
};

export const getTableCategoriesHeaders = (): Field[] => {
  return CategoryManagementPageConfig.headers as Field[];
};

export const getTableCategoriesFields = (): Field[] => {
  return CategoryManagementPageConfig.fields as Field[];
};

export const getTableApplicationsHeaders = (): Field[] => {
  return ApplicationManagementPageConfig.headers as Field[];
};

export const getTableApplicationsFields = (): Field[] => {
  return ApplicationManagementPageConfig.fields as Field[];
};

export const getPaymentTypes = (): string [] => {
  return PaymentTypes as string [];
}