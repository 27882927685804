import React from "react";
import {
  Box,
  Card,
  Typography,
  IconButton,
  TextField,
} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { ShoppingCartItem } from "../Models/ShoppingCartItem";
import { useCart } from "../context/CartContext";

interface CartProductCardProps {
  item: ShoppingCartItem;
  productDetails: any;
}

const CartProductCard: React.FC<CartProductCardProps> = ({
  item,
  productDetails,
}) => {
  const { product, quantity } = item;
  const { updateQuantity, removeFromCart } = useCart();

  return (
    <Box
      component={Card}
      sx={{
        display: "flex",
        flexDirection: "column",
        p: 2,
        mb: 2,
        borderRadius: 2,
        boxShadow: 2,backgroundColor:
        productDetails.details.length > 1 ? "rgba(255, 215, 0, 0.1)" : "background.paper",
      }}
    >
      <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>
        {product.name}
      </Typography>
      
      {productDetails.details.map((lot: any) => (
        <Box
          key={lot.id}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            my: 1,
          }}
        >
          <Typography variant="body2">
            Lote {lot.id}: ${lot.unitSalePrice?.toFixed(2)} x {lot.quantity} unidades
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              onClick={() => updateQuantity(Number(product.id), quantity - 1, lot.stock)}
              disabled={true}
            >
              <RemoveIcon />
            </IconButton>

            <TextField
              value={lot.quantity}
              onChange={(e) => updateQuantity(Number(product.id), parseInt(e.target.value) || 1, product.stock) }
              type="number"
              inputProps={{ min: 1, max: lot.stock }}
              disabled={true}
              sx={{
                mx: 1,
                width: 50,
                textAlign: "center",
                "& input": { textAlign: "center", padding: "8px" },
              }}
            />

            <IconButton
              onClick={() => updateQuantity(Number(product.id), quantity + 1, lot.stock )}
              disabled={true}
            >
              <AddIcon />
            </IconButton>
          </Box>
        </Box>
      ))}

      <Typography variant="body1" sx={{ fontWeight: "bold", mt: 1 }}>
        Total del producto: $
        {productDetails.details.reduce((total: any, lot: any) => total + lot.unitSalePrice * lot.quantity, 0).toFixed(2)}
      </Typography>

      <Box sx={{ textAlign: "right", mt: 1 }}>
        <IconButton color="secondary" onClick={() => removeFromCart(Number(product.id))}>
          <DeleteIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default CartProductCard;
