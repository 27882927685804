import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useNotification } from "./NotificationContext";
import { NotificationTypes } from "../Enums/NotificationTypes";
import sectorApplicationService from "../services/sectorApplicationService";
import { Sector } from "../Models/Sector";
import { useLoader } from "./LoaderContext";

interface SectorsContextType {
  sectorsAll: Sector[];
  addSector: (sector: Sector) => void;
  updateSector: (sector: Sector) => void;
  deleteSector: (sectorId: number) => void;
}

const SectorsContext = createContext<SectorsContextType | null>(null);

export const SectorsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { showNotification } = useNotification();
  const { setLoading } = useLoader();

  const [sectorsAll, setSectorsAll] = useState<Sector[]>(
    localStorage.getItem("sectorsAll")
      ? JSON.parse(localStorage.getItem("sectorsAll")!)
      : []
  );

  const addSector = async (sector: Sector) => {
    try {
      setLoading(true);
      await sectorApplicationService.addSectorApplication(sector);
      fetchSectors();
      showNotification(
        "Se ha agregado el sector correctamente.",
        NotificationTypes.SUCCESS
      );
    } catch (error) {
      console.error("Error agregando sector:", error);
      showNotification("Error al agregar el sector.", NotificationTypes.ERROR);
    } finally {
      setLoading(false);
    }
  };

  const updateSector = async (updatedSector: Sector) => {
    try {
      setLoading(true);
      await sectorApplicationService.updateSectorApplication(updatedSector);
      fetchSectors();
      showNotification(
        "Se ha actualizado el sector correctamente.",
        NotificationTypes.SUCCESS
      );
    } catch (error) {
      console.error("Error actualizando sector:", error);
      showNotification(
        "Error al actualizar el sector.",
        NotificationTypes.ERROR
      );
    } finally {
      setLoading(false);
    }
  };

  const deleteSector = async (sectorId: number) => {
    try {
      setLoading(true);
      await sectorApplicationService.deleteSectorApplication(sectorId);
      fetchSectors();
      showNotification(
        "Se ha eliminado el sector correctamente.",
        NotificationTypes.SUCCESS
      );
    } catch (error) {
      console.error("Error eliminando sector:", error);
      showNotification("Error al eliminar el sector.", NotificationTypes.ERROR);
    } finally {
      setLoading(false);
    }
  };

  const fetchSectors = useCallback(async () => {
    try {
      console.log("loading sectorsAll");
      setLoading(true);
      const fetchedSectors = await sectorApplicationService.getSectorApplication();
      setSectorsAll(fetchedSectors);
      localStorage.setItem("sectorsAll", JSON.stringify(fetchedSectors));
    } catch (error) {
      console.error("Error fetching sectors:", error);
      showNotification("Error al cargar los sectores", NotificationTypes.ERROR);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const loadSectorsIfEmpty = async () => {
      if (sectorsAll.length === 0) {
        console.log("SectorsAll está vacío, recuperando datos...");
        await fetchSectors();
      }
    };
    loadSectorsIfEmpty();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SectorsContext.Provider value={{ sectorsAll, addSector, updateSector, deleteSector }}>
      {children}
    </SectorsContext.Provider>
  );
};

export const useSectorsContext = () => {
  const context = useContext(SectorsContext);
  if (!context) {
    throw new Error("useSectorsContext must be used within a SectorsProvider");
  }
  return context;
};
