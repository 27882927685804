import React, { useState, MouseEvent } from "react";

import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Popover from "@mui/material/Popover";
import { alpha } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import { useUserDataContext } from "../../context/UserDataContext";
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "Inicio",
    icon: "eva:home-fill",
  },
  {
    label: "Perfil",
    icon: "eva:person-fill",
  },
  /*{
    label: "Configuracion",
    icon: "eva:settings-2-fill",
  },*/
];

export default function AccountPopover() {
  const { user, setShowSettingUser, onLogoutLogin} = useUserDataContext();
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const Navigate = useNavigate();

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const onLogout = () => {
    handleClose();
    onLogoutLogin();
  };

  const onClick = (label: string) => {
    if (label === "Perfil") {
      setShowSettingUser(true);
    } else if (label === "Inicio") {
      setShowSettingUser(false);
      Navigate("/");
    }
  };

  const handleClose = () => {
    setOpen(null); // Cierra el Popover
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src={user?.urlimage}
          alt={user?.name}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        ></Avatar>
      </IconButton>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        disableScrollLock
        sx={{
          p: 0,
          mt: 1,
          ml: 0.75,
          width: 200,
        }}
      >
        <Box sx={{ my: 1.5, px: 2 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.name}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem key={option.label} onClick={() => onClick(option.label)}>
            {option.label}
          </MenuItem>
        ))}

        <Divider sx={{ borderStyle: "dashed", m: 0 }} />

        <MenuItem
          disableRipple
          disableTouchRipple
          onClick={onLogout}
          sx={{ typography: "body2", color: "error.main", py: 1.5 }}
        >
          Cerrar Sesión
        </MenuItem>
      </Popover>
    </>
  );
}
