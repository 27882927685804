import React from "react";
import { Navigate } from "react-router-dom";
import { useUserDataContext } from "../context/UserDataContext"; // Asegúrate de tener este contexto

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useUserDataContext();
  const isLoggedIn = !!user?.code;

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
