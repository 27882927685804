import { OrderPurchase, OrderPurchaseDetail } from "../Models/OrderPurchase";
import { ORDER_PURCHASES_API_URL } from "../config/apiConfig";
import { apiService } from "./apiService";

const orderPurchaseService = {
    getOrders: async (startDate?: string, endDate?: string): Promise<OrderPurchase[]> => {
        const queryParams: Record<string, string> = {};
        if (startDate) {
            queryParams.startDate = startDate;
        }        
        if (endDate) {
            queryParams.endDate = endDate;
        }
        return await apiService(`${ORDER_PURCHASES_API_URL}`,{
            queryParams: queryParams
        });
    },

    addOrder: async (orderReq: any): Promise<void> => {

        return await apiService(ORDER_PURCHASES_API_URL, {
            method: 'POST',
            body: orderReq
        });
    },

    updateOrder: async (orderReq: any): Promise<void> => {
        const url = `${ORDER_PURCHASES_API_URL}`;
        return await apiService(url, {
            method: 'PUT',
            body: orderReq,
        });
    },

    deleteOrder: async (id: number): Promise<void> => {
        const url = `${ORDER_PURCHASES_API_URL}/${id}`;
        return await apiService(url, {
            method: 'DELETE',
            excludeAppId: true
        });
    },

    updateOrderDetailActiveStatus: async (orderReq: OrderPurchaseDetail): Promise<void> => {
        const id = orderReq.id;
        const url = `${ORDER_PURCHASES_API_URL}/UpdateActiveStatus/${id}`;
        return await apiService(url, {
            method: 'PUT',
            body: orderReq.active,
            excludeAppId: true
        });
    },
};

export default orderPurchaseService;
