import IconButton from "@mui/material/IconButton";
import Iconify from "../components/Iconify";
import { useUserDataContext } from "../context/UserDataContext";

export default function ThemePopover() {
  const { user, toggleDarkMode } = useUserDataContext();

  const handleThemeChange = () => {
    toggleDarkMode();
  };

  return (
    <IconButton
      onClick={handleThemeChange}
      sx={{
        width: 40,
        height: 40,
        bgcolor: "action.hover",
        "&:hover": {
          bgcolor: "action.selected",
        },
      }}
    >
      <Iconify icon={ !user?.darkMode ? 'eva:sun-fill' : 'eva:moon-fill'} />
    </IconButton>
  );
}
