
import { TypeUser } from "../Models/TypeUser";
import { User } from "../Models/User";
import { USER_API_URL } from "../config/apiConfig";
import { apiService } from "./apiService";

const userService = {
    getUsers: async (): Promise<User[]> => {
        return await apiService(USER_API_URL, {
            method: 'GET',
            excludeAppId: true
        });
    },

    addUser: async (userReq: User): Promise<void> => {
        return await apiService(USER_API_URL, {
            method: 'POST',
            body: userReq,
            excludeAppId: true,
            addAuditFields: true
        });
    },

    addUserImage: async (userReq: User): Promise<void> => {
        return await apiService(USER_API_URL + '/AddImageUser', {
            method: 'POST',
            body: userReq,
            excludeAppId: true
        });
    },

    updateUser: async (userReq: User): Promise<any> => {
        const url = `${USER_API_URL}/${userReq.id}`;
        return await apiService(url, {
            method: 'PUT',
            body: userReq,
            excludeAppId: true
        });
    },

    deleteUser: async (idUsuario: number): Promise<void> => {
        const url = `${USER_API_URL}/${idUsuario}`;
        return await apiService(url, {
            method: 'DELETE',
            excludeAppId: true
        });
    },

    getUserTypes: async (): Promise<TypeUser[]> => {
        const url = `${USER_API_URL}/Type/typeUser`;
        return await apiService(url, {
            method: 'GET',
            excludeAppId: true
        });
    },
};

export default userService;
