import { REPORT_API_URL } from "../config/apiConfig";
import { SalesReportResponse } from "../Models/SalesReportResponse";
import { apiService } from "./apiService";

const reportService = {
    getSalesReport: async (): Promise<SalesReportResponse[]> => {
        return await apiService(REPORT_API_URL, {
            method: 'GET',
            excludeAppId: false
        });
    }
}

export default reportService;
