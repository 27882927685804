import { set, sub } from 'date-fns';

export const setTime = (date: any, hours: any, minutes: any) => {
  return set(date, { hours, minutes });
};

export const subtractTime = (date: any, { days = 0, hours = 0, minutes = 0 }) => {
  return sub(date, { days, hours, minutes });
};

export const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const isAfter = (date1: any, date2: any) => {
  return new Date(date1) > new Date(date2);
};

export const isBefore = (date1: any, date2: any) => {
  return new Date(date1) < new Date(date2);
};

export const formatDate = (date: any) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const day = String(d.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const addDays = (date: any, days: any) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return formatDate(result);
};

export const subtractDays = (date: any, days: any) => {
  const result = new Date(date);
  result.setDate(result.getDate() - days);
  return formatDate(result);
};

export const subtractMonths = (date: any, months: number) => {
  const result = new Date(date);
  result.setMonth(result.getMonth() - months);
  return formatDate(result);
};

/**
 * Convierte un objeto Date a un string en el formato especificado.
 * 
 * @param {Date} date - El objeto Date a convertir.
 * @param {string} [format='YYYY-MM-DD'] - El formato de salida.
 * @returns {string} - La fecha en el formato especificado.
 */
export function parseDateToString(date: Date, format: string = 'YYYY-MM-DDTHH:mm:ss'): string {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const day = String(d.getDate()).padStart(2, '0');
  const hours = String(d.getHours()).padStart(2, '0');
  const minutes = String(d.getMinutes()).padStart(2, '0');
  const seconds = String(d.getSeconds()).padStart(2, '0');

  switch (format) {
    case 'YYYY-MM-DDTHH:mm:ss': // ISO 8601 formato
      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    case 'YYYY-MM-DD':
      return `${year}-${month}-${day}`;
    case 'DD-MM-YYYY hh:mm:ss': // No recomendado para backend
      return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    default:
      return `${year}-${month}-${day}`;
  }
}
/**
 * Convierte una cadena de texto en una fecha Date usando el formato especificado o en formato ISO.
 * Si no se proporciona un formato, intenta el formato ISO o usa 'DD-MM-YYYY' por defecto.
 * 
 * @param {string} dateString - La fecha en formato string.
 * @param {string} [format='DD-MM-YYYY'] - El formato de la fecha de entrada.
 * @returns {Date} - El objeto Date correspondiente a la fecha.
 */
export function parseStringToDate(dateString: any, format: string = 'DD-MM-YYYY'): Date {
  if (typeof dateString !== 'string') return new Date();

  // Verifica si la cadena es un formato ISO válido
  const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;
  if (isoRegex.test(dateString)) {
    return new Date(dateString); // Maneja el formato ISO directamente
  }

  // Verifica si la fecha está en formato ISO
  if (!format && !isNaN(Date.parse(dateString))) {
    return new Date(dateString);
  }

  let year, month, day;

  switch (format.toUpperCase()) {
    case 'YYYY-MM-DD':
      [year, month, day] = dateString.split('-').map(Number);
      return new Date(year, month - 1, day);

    case 'DD-MM-YYYY':
    default:
      [day, month, year] = dateString.split('-').map(Number);
      return new Date(year, month - 1, day);
  }
}

/**
 * Convierte una instancia de Date a un string con formato ISO (YYYY-MM-DD).
 * @param date - Fecha que se desea formatear.
 * @returns Un string con la fecha formateada en ISO (YYYY-MM-DD).
 */
export const formatDateToISO = (date: Date): string => {
  if (!(date instanceof Date)) {
    throw new Error("El parámetro debe ser una instancia válida de Date");
  }
  
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Meses son 0-indexados
  const day = String(date.getDate()).padStart(2, "0");
  
  return `${year}-${month}-${day}`;
};