import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
  } from "react";
  import { useNotification } from "./NotificationContext";
  import { NotificationTypes } from "../Enums/NotificationTypes";
  import { OrderSale } from "../Models/OrderSale";
  import { useLoader } from "./LoaderContext";
  import orderSaleService from "../services/orderSalesService";
  
  interface OrderSalesContextType {
    orderSalesAll: OrderSale[];
    addOrderSale: (orderSale: OrderSale) => void;
    updateOrderSale: (orderSale: OrderSale) => void;
    deleteOrderSale: (orderSaleId: number) => void;
    payOrderSaleDetail: (orderSaleDetailId: number) => void;
    payOrderSale: (rderSaleId: number) => void;
    fetchOrderSales: (startDate?: string, endDate?: string ) => void;
  }
  
  const OrderSalesContext = createContext<OrderSalesContextType | null>(null);
  
  export const OrderSalesProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
  }) => {
    const { showNotification } = useNotification();
    const { setLoading } = useLoader();
  
    const [orderSalesAll, setOrderSalesAll] = useState<OrderSale[]>(
      localStorage.getItem("orderSalesAll") ? JSON.parse(localStorage.getItem("orderSalesAll")!) : []
    );
  
    useEffect(() => {
      localStorage.removeItem("orderSalesAll");
      if (orderSalesAll.length > 0) {
        localStorage.setItem("orderSalesAll", JSON.stringify(orderSalesAll));
      }
    }, [orderSalesAll]);
  
    const addOrderSale = async (orderSale: OrderSale) => {
      try {
        setLoading(true);
        await orderSaleService.addOrder(orderSale);
        fetchOrderSales();
        showNotification("Se ha agregado la venta correctamente.", NotificationTypes.SUCCESS);
      } catch (error) {
        console.error("Error agregando venta:", error);
        showNotification("Error al agregar la venta.", NotificationTypes.ERROR);
      } finally {
        setLoading(false);
      }
    };
  
    const updateOrderSale = async (updatedOrderSale: OrderSale) => {
      try {
        setLoading(true);
        await orderSaleService.updateOrder(updatedOrderSale);
        fetchOrderSales();
        showNotification("Se ha actualizado la venta correctamente.", NotificationTypes.SUCCESS);
      } catch (error) {
        console.error("Error actualizando venta:", error);
        showNotification("Error al actualizar la venta.", NotificationTypes.ERROR);
      } finally {
        setLoading(false);
      }
    };
  
    const deleteOrderSale = async (orderSaleId: number) => {
      try {
        setLoading(true);
        await orderSaleService.deleteOrder(orderSaleId);
        fetchOrderSales();
        showNotification("Se ha eliminado la venta correctamente.", NotificationTypes.SUCCESS);
      } catch (error) {
        console.error("Error eliminando venta:", error);
        showNotification("Error al eliminar la venta.", NotificationTypes.ERROR);
      } finally {
        setLoading(false);
      }
    };

    const payOrderSaleDetail = async (orderSaleDetailId: number) => {
      try {
        setLoading(true);
        await orderSaleService.payOrderSaleDetail(orderSaleDetailId);
        fetchOrderSales();
        showNotification(
          "Se ha actualizado la venta correctamente.",
          NotificationTypes.SUCCESS
        );
      } catch (error) {
        console.error("Error actualizando venta:", error);
        showNotification("Error al actualizar la venta.",NotificationTypes.ERROR);
      } finally {
        setLoading(false);
      }
    };

    const payOrderSale = async (orderSaleId: number) => {
      try {
        setLoading(true);
        await orderSaleService.payOrderSale(orderSaleId);
        fetchOrderSales();
        showNotification("Se ha actualizado la venta correctamente.",NotificationTypes.SUCCESS);
      } catch (error) {
        console.error("Error actualizando venta:", error);
        showNotification("Error al actualizar la venta.",NotificationTypes.ERROR);
      } finally {
        setLoading(false);
      }
    };
  
    const fetchOrderSales = useCallback(async (startDate?: string, endDate?: string) => {
      try {
        setLoading(true);
        console.log("loading orderSalesAll");
        const fetchedOrderSales = await orderSaleService.getOrders(startDate, endDate);
        setOrderSalesAll(fetchedOrderSales);
        localStorage.setItem("orderSalesAll", JSON.stringify(fetchedOrderSales));
      } catch (error) {
        console.error("Error fetching order sales:", error);
        showNotification("Error al cargar las ventas", NotificationTypes.ERROR);
      } finally {
        setLoading(false);
      }
    // eslint-disable-next-line
    }, []);
  
    useEffect(() => {
      const loadOrderSalesIfEmpty = async () => {
        console.log("orderSalesAll, recuperando datos...");
        await fetchOrderSales();
      };
      loadOrderSalesIfEmpty();
    // eslint-disable-next-line
    }, []);
  
    return (
      <OrderSalesContext.Provider
        value={{
          orderSalesAll,
          addOrderSale,
          updateOrderSale,
          deleteOrderSale,
          payOrderSaleDetail,
          payOrderSale,
          fetchOrderSales
        }}
      >
        {children}
      </OrderSalesContext.Provider>
    );
  };
  
  export const useOrderSalesContext = () => {
    const context = useContext(OrderSalesContext);
    if (!context) {
      throw new Error(
        "useOrderSalesContext must be used within an OrderSalesProvider"
      );
    }
    return context;
  };
  