import { Product } from "../Models/Product";
import { PRODUCT_API_URL } from "../config/apiConfig";
import { apiService } from "./apiService";

const productService = {
    getProducts: async (): Promise<Product[]> => {
        return await apiService(PRODUCT_API_URL);
    },

    getAllProducts: async (): Promise<Product[]> => {
        return await apiService(PRODUCT_API_URL, {
            method: 'GET',
            excludeAppId: true
        });
    },

    getAllProductsByCategory: async (categoriesIds: number[]): Promise<Product[]> => {
         const url = `${PRODUCT_API_URL}/categories?categoriesIds=${categoriesIds.join('&categoriesIds=')}`;
        return await apiService(url, {
            method: 'GET',
            excludeAppId: true
        });
    },


    addProduct: async (productReq: Product): Promise<Product> => {
        return await apiService(PRODUCT_API_URL, {
            method: 'POST',
            body: productReq,
            excludeAppId: true
        });
    },

    addProductWithImage: async (productReq: Product): Promise<Product> => {
        const url = `${PRODUCT_API_URL}/AddProductWithImage`;
        return await apiService(url, {
            method: 'POST',
            body: productReq,
            excludeAppId: true,
            addAuditFields: true
        });
    },

    updateProduct: async (productReq: Product): Promise<Product> => {
        const url = `${PRODUCT_API_URL}/${productReq.id}`;
        return await apiService(url, {
            method: 'PUT',
            body: productReq,
            excludeAppId: true,
            addAuditFields: true
        });
    },

    updateProductWithImage: async (productReq: Product): Promise<Product> => {
        const url = `${PRODUCT_API_URL}/${productReq.id}/WithImage`;
        return await apiService(url, {
            method: 'PUT',
            body: productReq,
            excludeAppId: true
        });
    },

    deleteProduct: async (id: number): Promise<void> => {
        const url = `${PRODUCT_API_URL}/${id}`;
        return await apiService(url, {
            method: 'DELETE',
            excludeAppId: true
        });
    },

    getProductById: async (idProduct: number): Promise<Product> => {
        return await apiService(`${PRODUCT_API_URL}/${idProduct}`, {
            method: 'GET',
            excludeAppId: true
        });
    },
};

export default productService;
