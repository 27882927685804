
import { CASH_REGISTER_API_URL } from "../config/apiConfig";
import { CashRegister } from "../Models/CashRegisterModel";
import { apiService } from "./apiService";

const cashRegisterService = {
    getCashRegister: async (): Promise<CashRegister> => {
        return await apiService(CASH_REGISTER_API_URL, {
            method: 'GET',
            addAuditFields: true
        });
    },
    addCashRegister: async (cashRegisterReq: CashRegister): Promise<void> => {
        return await apiService(CASH_REGISTER_API_URL, {
            method: 'POST',
            body: cashRegisterReq,
            excludeAppId: true
        });
    },

    updateCashRegister: async (cashRegisterReq: CashRegister): Promise<void> => {
        const url = `${CASH_REGISTER_API_URL}/${cashRegisterReq.id}`;
        return await apiService(url, {
            method: 'PUT',
            body: cashRegisterReq,
            excludeAppId: true
        });
    },

    deleteCashRegister: async (id: number): Promise<void> => {
        const url = `${CASH_REGISTER_API_URL}/${id}`;
        return await apiService(url, {
            method: 'DELETE',
            excludeAppId: true
        });
    },
};

export default cashRegisterService;
